import { Request } from '@/components/authored-page-routes/authored-page-routes.interface';
import { COOKIE_DELTA, LoggedInStatus, PLP_QUERY_PARAM_KEYS } from '@/data/constants';
import { getCartData } from '@/ssr/cart-data';
import { getSdmWebTokens } from '@/ssr/sdm-web-tokens';
import { decodeUrlParams, getBannerHostUrlSSR, getLoggedInUserInfoSSR, getSiteId, isLoggedIn } from '@/utils';
import util, { type InspectOptions } from 'util';
import { getKameleoonClient, getKameleoonFlags } from './kameleoon/kameloon-sdk';

interface QueryParams {
  pageQueryParam: { from: string };
  sortQueryParam: { [key: string]: string };
  filtersQueryParam: { [key: string]: string[] };
  redirectQueryParam: { originalTarget: string | null };
}

const handleError = <T>(val: PromiseSettledResult<T>): T | null => {
  const logConfig: InspectOptions = { showHidden: false, depth: 4, colors: true };

  if (val.status === 'rejected') {
    console.error(util.inspect(val.reason, logConfig));

    return null;
  }

  return val.value;
};

export const handleErrorResponse = (response: any, bffData?: any) => {
  const errorMessage = {
    status: response.status,
    statusText: response.statusText,
    ...bffData,
  };

  return {
    error: errorMessage,
    isError: !response.ok,
    viewDefinition: null,
  };
};

export const fetchDataAndHandleErrors = async (context: any, getViewDefinitionFn: any, pageType?: string) => {
  const visitorCode = context?.req?.cookies.kameleoonVisitorCode || '';
  const ciamAccessToken = context?.req?.cookies?.ciamAccessToken;
  const userInfo = getLoggedInUserInfoSSR(ciamAccessToken);
  const warehouseKey = userInfo?.pcid || visitorCode;

  //initialize Kameleoon SDK - experimentation tool for feature flags
  const kamClient = await getKameleoonClient();
  let activeKamFeatureFlags = null;

  if (kamClient) {
    activeKamFeatureFlags = await getKameleoonFlags(visitorCode, warehouseKey);
  } else {
    console.warn('WARNING:: Unable to fetch Kameleoon instance!');
  }

  // Get results concurrently
  const [viewDefinitionResults, themeTokens, cartData] = await Promise.allSettled([
    pageType
      ? getViewDefinitionFn(context, pageType, activeKamFeatureFlags)
      : getViewDefinitionFn(context, activeKamFeatureFlags),
    getSdmWebTokens(),
    getCartData(context),
  ]);

  // 404 page, a very specific error where midtier sends back an empty string for an unknown pageId, and we try to
  // serialize it as JSON
  if (
    viewDefinitionResults.status === 'rejected' &&
    viewDefinitionResults.reason instanceof Error &&
    viewDefinitionResults.reason.name === 'FetchError' &&
    (viewDefinitionResults.reason as Record<string, any>).type === 'invalid-json'
  ) {
    return { viewDefinitionResults: null, themeTokens: null, cartData: null, kamFlags: null };
  }

  return {
    viewDefinitionResults: handleError(viewDefinitionResults),
    themeTokens: handleError(themeTokens),
    cartData: handleError(cartData),
    kamFlags: activeKamFeatureFlags,
  };
};

export const getLoggedInStatusSSR = (req: Request): string => {
  return isLoggedIn(req) ? LoggedInStatus.CURRENT : LoggedInStatus.ANONYMOUS;
};

export const checkIfCookieExpiredSSR = (modifiedDate: string | undefined, req: Request): boolean => {
  return (
    getLoggedInStatusSSR(req) === LoggedInStatus.ANONYMOUS &&
    new Date().getTime() > Number(modifiedDate) + Number(COOKIE_DELTA.TWO_DAYS)
  );
};

export const getExistingCartGuidSSR = (req: Request): string => {
  const siteId = getSiteId(req);
  const cartIdHelios = `${siteId}-cart-helios`;
  const cartIdHeliosModifiedDate = `${siteId}-cart-helios-modified-date`;
  const heliosGuid = req?.cookies?.[cartIdHelios];
  const modifiedDate = req?.cookies?.[cartIdHeliosModifiedDate];
  // modifiedDate lets us know the last time an item was added to the bag as an ANNOYMOUS user.
  const cookieExpired = checkIfCookieExpiredSSR(modifiedDate, req);

  return cookieExpired ? '' : heliosGuid || '';
};

export const withAuthentication = (getServerSidePropsFn: any) => (ctx: { req: Request }) => {
  const isLoggedInUser = isLoggedIn(ctx.req);
  if (!isLoggedInUser) {
    const hostname = ctx?.req?.headers?.host || '';
    const banner = getBannerHostUrlSSR(hostname);
    const login = `${banner}/login`;
    return {
      redirect: {
        permanent: false,
        destination: login,
      },
    };
  }

  return getServerSidePropsFn(ctx);
};

export const getQueryParams = (query: Record<string, any>): QueryParams => {
  const { page = 1, sort, ...filters } = query;
  const PAGE_SIZE = 36;
  const totalResultsQty = page > 1 ? ((page - 1) * PAGE_SIZE).toString() : '0';

  // Process pagination
  const pageQueryParam = { from: totalResultsQty };

  // Process Sort
  let sortQueryParam = {};

  if (sort !== undefined) {
    sortQueryParam = { [sort]: '' };
  }

  // Process filters
  const { SEARCH_REDIRECT_PARAM_KEY } = PLP_QUERY_PARAM_KEYS;
  const excludedKeys = Object.values(PLP_QUERY_PARAM_KEYS);

  const filtersQueryParam: { [key: string]: string[] } = {};
  for (const [key, value] of Object.entries(filters)) {
    if (!(excludedKeys.includes(key as any) || key?.includes('-'))) {
      filtersQueryParam[key] = Array.isArray(value)
        ? value.map((v: string) => decodeUrlParams(v))
        : [decodeUrlParams(value)];
    }
  }

  const redirectQueryParam = {
    originalTarget: query?.[SEARCH_REDIRECT_PARAM_KEY] || null,
  };

  return { filtersQueryParam, sortQueryParam, pageQueryParam, redirectQueryParam };
};
