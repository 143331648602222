import { Request } from '@/components/authored-page-routes/authored-page-routes.interface';
import { LoggedInStatus, PAGE_ID, banners } from '@/data/constants';
import { isPharmaprixBanner } from '@/utils';
import { IPCOptimumAccount } from '@ldp/sdm-contexts';
import { ContextKey, EventName, getDataLayerName, initializeSnowplow, snowplow } from '@sdm/analytics-helper-library';
import UniversalCookies from 'universal-cookie';
import { getExistingCartGuid, getLoggedInStatus, getLoggedInUserInfo } from 'Utils/auth';
import { IGlobalContextFields } from './analytics.interface';
import { HELIOS_ANALYTICS_EVENT_TYPES, HELIOS_COMPONENT_NAMES, PAGE_CMS, SNOWPLOW, USER_CONTEXT } from './constants';
import {
  IAdContext,
  ICartContext,
  IComponentContext,
  IFilterSortContext,
  IPageContext,
  IPaginationContext,
  IProductContext,
  IShoppingExperienceContext,
  IUserContext,
} from './snowplow-context.interface';

const cookieStore = new UniversalCookies();
export const dataLayerName: string = getDataLayerName();

const { PAGE_NAMES, PAGE_TEMPLATES, PAGE_SECTIONS, EVENT_NAMES, PAGE_PATH, PLP_PAGE_PATH, BEAUTY_SUBCATEGORIES } =
  SNOWPLOW;

const { HOME_ELECTRONICS, LANDING, BABY_CHILD, PERSONAL_CARE, HEALTH, BEAUTY, WELLNESS_SHOP, LUXURY_BEAUTY } = PAGE_ID;
const {
  HOME_LANDING_PAGE,
  PERSONAL_CARE_LANDING_PAGE,
  HEALTH_LANDING_PAGE,
  ELECTRONICS_LANDING_PAGE,
  BABY_AND_CHILD_LANDING_PAGE,
  BEAUTY_LANDING_PAGE,
  SEARCH_PLP,
  WELLNESS_SHOP_PAGE,
  LUXURY_BEAUTY_PAGE,
} = PAGE_NAMES;

const {
  HELIOS_ADD_TO_CART,
  HELIOS_PRODUCT_CAROUSEL,
  HELIOS_PRODUCT_EVENT,
  WAITING_ROOM_MODAL_VIEW,
  HELIOS_AD_TRACKING,
  HELIOS_NAV_CLICK,
  HELIOS_PAGE_TRACKING,
  HELIOS_SORT_TRACKING,
  HELIOS_FILTER_TRACKING,
  HELIOS_REFRESH_LINKS_CLICK_TRACKING,
  PRODUCT_CLICK,
  HELIOS_PRODUCT_SEARCH_TRACKING,
  HELIOS_HORIZONTAL_FEATURE_CARDS,
  HELIOS_FORM_TRACKING,
  HELIOS_BANNER_CAROUSEL,
  HELIOS_ACQUISITION_FORM_SUBMIT,
  HELIOS_EXCEPTION_TRACKING,
} = HELIOS_ANALYTICS_EVENT_TYPES;

/**
 * A function to to toggle the snowplow collector endponts via the query parameter
 * Usage: For instance, adding snowplow-env="qa" as a parameter
 * would direct data into the snowplow qa env
 * @returns {string} The desire Snowplow collector endpoint
 */
export const getSnowplowEnv = () => {
  const snowplowEnvParam = new URLSearchParams(window.location.search).get('snowplow-env');
  switch (snowplowEnvParam) {
    case 'qa':
      return SNOWPLOW.ENV.QA;
    case 'dev':
      return SNOWPLOW.ENV.DEV;
    case 'prod':
      return SNOWPLOW.ENV.PROD;
    default:
      return process.env.NEXT_PUBLIC_SNOWPLOW_ENV === 'prod' ? SNOWPLOW.ENV.PROD : SNOWPLOW.ENV.DEV;
  }
};

export const initializeAnalytics = () => {
  const isVerbose = process.env.NEXT_PUBLIC_SNOWPLOW_IS_VERBOSE === 'true';
  const snowPlowEnv = getSnowplowEnv();

  const configOptions = {
    enableLinkClickAutoTracking: true,
    isVerbose: isVerbose,
  };

  initializeSnowplow(snowPlowEnv, configOptions);
};

export const mapPageIdToPageName: { [key: string]: string } = {
  [HOME_ELECTRONICS]: ELECTRONICS_LANDING_PAGE,
  [LANDING]: HOME_LANDING_PAGE,
  [BABY_CHILD]: BABY_AND_CHILD_LANDING_PAGE,
  [PERSONAL_CARE]: PERSONAL_CARE_LANDING_PAGE,
  [HEALTH]: HEALTH_LANDING_PAGE,
  [BEAUTY]: BEAUTY_LANDING_PAGE,
  [WELLNESS_SHOP]: WELLNESS_SHOP_PAGE,
  [LUXURY_BEAUTY]: LUXURY_BEAUTY_PAGE,
};

export const mapPageIdToPageTemplate = (pageId: string) => {
  const departmentLandingPages: string[] = [HOME_ELECTRONICS, BABY_CHILD, PERSONAL_CARE, HEALTH, BEAUTY];

  if (departmentLandingPages.includes(pageId)) {
    return PAGE_TEMPLATES.DEPARTMENT_TEMPLATE;
  }
  const pathname = window?.location.pathname || '';
  const plpPagePaths = Object.values(PLP_PAGE_PATH);
  if (plpPagePaths.some((path) => pathname.includes(path))) {
    return PAGE_TEMPLATES.PLP_TEMPLATE;
  }
  return null;
};

// Temp helper function until BFF sends back pageName
const getPageNameFromPath = (pageId: string) => {
  const pathname = window?.location.pathname || '';
  if (pathname.includes(PAGE_PATH.SEARCH)) {
    return SEARCH_PLP;
  }
  if (pathname.includes(PAGE_PATH.CATEGORIES) || pathname.includes(PAGE_PATH.CATEGORIES_ABBR)) {
    const splitPath = pathname.split('/c/');
    const parsePath = splitPath?.[0]?.split('/');

    const formattedPageName = getPlpPageName(parsePath, pageId);
    return formattedPageName;
  }
  return null;
};

export const determineTypeOfPlp = (formattedPlpArray: string[]): string => {
  const beautySubCategories = Object.values(BEAUTY_SUBCATEGORIES);

  const shouldFormatBeautyPlp = beautySubCategories.some((element) => formattedPlpArray[0] === element);
  if (shouldFormatBeautyPlp) formattedPlpArray.unshift('beauty');
  const isSubCategoryPlp = formattedPlpArray.length === 2;
  return isSubCategoryPlp ? '|sub-categories' : '|plp';
};

export const getPlpPageName = (pathArray: string[], pageId: string) => {
  // a regular plp always has 'categories' or 'collections' at index 2
  const isBrandPlp = pathArray?.[2] !== 'categories' && pathArray?.[2] !== 'collections';
  const filteredPath = pathArray?.filter(
    (breadcrumb) => breadcrumb !== 'shop' && breadcrumb !== 'brands' && breadcrumb !== 'categories' && !!breadcrumb
  );
  const plpName = !!filteredPath.length ? filteredPath : filteredPath.concat(pageId.toLowerCase());
  const formattedPlpArray = plpName?.map((breadcrumb) => breadcrumb.replace(/(-%2526|-%26|%)/g, ''));

  if (isBrandPlp) return formattedPlpArray.join('|').concat('|brand-plp');

  const typeOfPlp = determineTypeOfPlp(formattedPlpArray);
  const formattedPageName = formattedPlpArray.join('|').concat(typeOfPlp).toLowerCase();
  return formattedPageName;
};

/** function to determine content pages */
const getPageSection = (pageName: string) => {
  if (pageName.includes(PAGE_PATH.HEALTH)) return PAGE_SECTIONS.HEALTH;

  return pageName.includes(PAGE_NAMES.CONTACT_US) || pageName.includes(PAGE_NAMES.SIGN_UP)
    ? PAGE_SECTIONS.CONTENT
    : PAGE_SECTIONS.SHOP;
};

export const getBreadcrumbsFromURL = () => {
  const pathname = window?.location.pathname || '';

  const mapPageToUrlPattern = [PAGE_PATH.CATEGORIES_ABBR, PAGE_PATH.PDP];
  const urlPattern = mapPageToUrlPattern.find((pageType) => pathname.includes(pageType));
  if (!urlPattern) return null;

  const breadcrumbs = pathname.split(urlPattern)[0].split('/');
  // same category url text for EN and FR
  const filteredBreadcrumbs = breadcrumbs?.filter(
    (breadcrumb) => breadcrumb !== 'c' && breadcrumb !== 'categories' && !!breadcrumb
  );
  const formattedBreadcrumbs = filteredBreadcrumbs?.map((breadcrumb) => breadcrumb.replace(/(-%26|%)/g, ''));
  return formattedBreadcrumbs;
};

/**
 * A function to normalize a value string to be used for Snowplow.
 * This converts a string to only use lowercase printable ASCII characters with dashes.
 * See https://aticleworld.com/printable-ascii-characters-list/ for accepted characters.
 *
 * @param {string} text - the text to convert to a value for Snowplow
 * @returns {string}
 */
export const normalizeStringValueForSnowplow = (text: string): string => {
  if (text === '') return '';
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Normalize French characters
  const cleanText = normalizedText.replace(new RegExp(`[^ -~]|[$]|[_+]`, 'gu'), ' ').trim(); // Remove all non-ASCII characters and dollar signs
  const spText = cleanText.toLowerCase().replace(/\s+/g, '-'); //  Convert text to lowercase, replace all spaces with dashes
  return spText;
};

/**
 * A Snowplow helper function to check whether the event has already been created. This is done by checking for existing events in the window.analyticsLayer with the same name, and whether the latest instance of the event has the same specified data
 *
 * @param {string} eventName - the Snowplow event name
 * @param {string} dataObjectPath - the key or path (in dot notation) within the window.analyticsLayer to the existing object data
 * @param {Object|string} newData - the new data to validate if it is the same as within the existing analyticsLayer object
 * @param {integer} threshold - how many events to look back at
 * @returns {boolean}
 */

export const hasExistingEvent = (eventName: string, dataObjectPath: string, newData: Object | string): boolean => {
  const existingEvents = window?.[dataLayerName]?.filter((analyticsEvent) => analyticsEvent.event === eventName);
  // Get the data in the existing event by traversing the path specified by the dataObjectPath
  return (
    existingEvents?.some((event) => {
      const existingObjectValue = dataObjectPath.split('.').reduce((accumulator, currentValue) => {
        return accumulator?.[currentValue];
      }, event);
      return Boolean(event && JSON.stringify(existingObjectValue) === JSON.stringify(newData));
    }) ?? false
  );
};

const getUserContextData = (pcOptimumAccount: IPCOptimumAccount): IUserContext => {
  const { walletId } = pcOptimumAccount || {};
  const { ANONYMOUS, CURRENT, GUEST_LOGGED_IN } = LoggedInStatus;
  const { REGISTRATION_STATUS } = USER_CONTEXT;

  const mapLoginStatus: { [key: string]: string } = {
    [ANONYMOUS]: 'not-logged-in',
    [CURRENT]: 'logged_in',
    [GUEST_LOGGED_IN]: 'guest_logged_in',
  };

  const getUserStatus = (status: string) => {
    if (mapLoginStatus[status]) {
      return mapLoginStatus[status];
    } else {
      return 'not-logged-in';
    }
  };

  const getUserPCId = () => {
    const ciamToken = getLoggedInUserInfo();
    const pcid = ciamToken?.pcid ?? '';
    return pcid;
  };

  const userContextData: IUserContext = {
    pcid_id: getUserPCId(),
    login_status: getUserStatus(getLoggedInStatus()),
    registration_status: walletId ? REGISTRATION_STATUS.REGISTERED : REGISTRATION_STATUS.UNKNOWN,
    pco_wallet_id: walletId ?? null,
  };
  return userContextData;
};

const getCartContextData = (cartCount: number): ICartContext => {
  const guid = getExistingCartGuid();

  const cartContextData = {
    cart_id: guid,
    cart_status: cartCount === 0 ? 'empty' : 'open',
    assortment_type: '',
    parent_cart_id: guid,
  };

  return cartContextData;
};

const getPageContextData = (pageId: string, locale: string, currentDevice: string): IPageContext => {
  const pageName = mapPageIdToPageName[pageId] || getPageNameFromPath(pageId) || pageId;
  const pageSection = getPageSection(pageName);
  const brandPlpId = pageName.includes('|brand-plp') && pageName.substring(0, pageName.indexOf('|'));
  const listingPageId = brandPlpId || (pageName !== SEARCH_PLP && pageId);

  const pageContextData = {
    page_name: pageName,
    page_section: pageSection,
    page_language: locale,
    page_template: mapPageIdToPageTemplate(pageId),
    site_type: currentDevice,
    page_url: window.location.href,
    page_breadcrumb: getBreadcrumbsFromURL(),
    page_cms: PAGE_CMS.CONTENTFUL_PAGE,
    contentful_page_id: pageId,
    listing_page_id: listingPageId || null,
  };
  return pageContextData;
};

const getShoppingExperienceContextData = (): IShoppingExperienceContext => {
  const shoppingExperienceContextData = {
    banner: isPharmaprixBanner() ? banners.PHARMAPRIX : banners.SHOPPERS,
  };
  return shoppingExperienceContextData;
};

export const trackSnowplow = (analyticsData: any, globalContextFields: IGlobalContextFields) => {
  const { cartCount, locale, currentDevice, pageId, pcOptimumAccount } = globalContextFields ?? {};

  if (window[dataLayerName])
    window[dataLayerName]?.push({
      ...analyticsData,
      [ContextKey.Cart]: getCartContextData(cartCount),
      [ContextKey.Page]: getPageContextData(pageId, locale, currentDevice),
      [ContextKey.User]: getUserContextData(pcOptimumAccount!),
      [ContextKey.ShoppingExperience]: getShoppingExperienceContextData(),
    });
  return;
};

export const getProductContextData = (context: any): IProductContext => {
  const { brand, code, name, quantity, badges, sellerName, prices, isSponsored } = context.productContext;
  const productContextData = {
    product_article_number: '',
    product_upc: code,
    product_name: name,
    product_quantity: quantity,
    product_loyalty_badge: badges?.loyaltyBadge || null,
    product_deal_badge: badges?.dealBadge?.text || '',
    product_text_badge: badges?.textBadge || null,
    product_price: prices.price.value,
    product_was_price: prices.wasPrice?.value,
    product_brand: brand,
    product_sponsor: null,
    product_is_sponsored: isSponsored || false,
    product_catalog: null,
    product_seller: sellerName,
    product_position: context.productPosition ?? null,
    product_liam: `SDM_${code}`,
  };
  return productContextData;
};

export const getAdProductContextData = (context: any): IProductContext | null => {
  const product = context.product;
  if (!product) return null;
  const adProductContextData = {
    product_article_number: product?.articleNumber || null,
    product_upc: product?.code || null,
    product_name: product?.name || null,
    product_quantity: product?.quantity || null,
    product_loyalty_badge: product?.badges?.loyaltyBadge || null,
    product_deal_badge: product?.badges?.dealBadge?.text || null,
    product_text_badge: product?.badges?.textBadge || null,
    product_price: product?.prices
      ? parseFloat(product.prices.price?.value)
      : parseFloat(product?.pricing.price) || null,
    product_was_price: product?.prices ? product.prices.wasPrice?.value : null,
    product_brand: product?.brand || null,
    product_sponsor: null,
    product_is_sponsored: product?.isSponsored || true,
    product_catalog: null,
    product_seller: product?.sellerName || null,
    product_position: product?.productPosition ?? null,
    product_liam: product?.code ? `SDM_${product?.code}` : null,
  };

  return adProductContextData;
};
export const getProductsArrayContextData = (context: any): IProductContext[] => {
  const productsArray = context.products.map((product: any) => {
    const { code, name, badges, prices, brand, isSponsored, productPosition } = product;
    return {
      product_article_number: '',
      product_upc: code,
      product_name: name,
      product_loyalty_badge: badges?.loyaltyBadge || null,
      product_deal_badge: badges?.dealBadge?.text || '',
      product_text_badge: badges?.textBadge || null,
      product_price: prices.price.value,
      product_was_price: prices.wasPrice?.value,
      product_brand: brand,
      product_is_sponsored: isSponsored || false,
      product_position: productPosition ?? null,
      product_liam: `SDM_${code}`,
    };
  });
  return productsArray;
};

export const getAdContextData = (context: any): IAdContext => {
  const {
    serving_id,
    placement_id,
    ad_group_id,
    brand,
    campaign_id,
    creative_id,
    encrypted_cost,
    keyword,
    product_article_number,
    target_keyword,
    target_match_type,
    target_page_type,
    creative_cost,
  } = context.ad || {};

  const adContextData: IAdContext = {
    serving_id: serving_id ? serving_id : ' ',
    placement_id: placement_id || null,
    campaign_id: campaign_id || '',
    ad_group_id: ad_group_id || null,
    //cost not supplied by helios
    cost: null,
    encrypted_cost: encrypted_cost || creative_cost?.encrypted_cost || null,
    keyword: keyword || null,
    creative_id: creative_id || null,
    product_article_number: product_article_number || null,
    brand: brand || null,
    target_keyword: target_keyword || null,
    target_match_type: target_match_type || null,
    target_page_type: target_page_type || null,
  };
  return adContextData;
};
const getComponentContextData = (context: any, pageId: string): IComponentContext => {
  const { engine, id, name, number_of_children, type, click_action, click_element, hierarchy, placement, position } =
    context.component || {};
  const componentContextData = {
    id: id || null,
    name: name || null,
    type: type || null,
    //use page_name or pageId value if placement is not provided
    placement: placement || mapPageIdToPageTemplate(pageId) || mapPageIdToPageName[pageId] || pageId,
    position: position || null,
    number_of_children: number_of_children || null,
    engine,
    click_action: click_action || null,
    click_element: click_element || null,
    hierarchy: hierarchy || null,
  };
  return componentContextData;
};

export const getSnowplowUserData = (req?: Request) => {
  const spCookie = '_spvid_id';
  const spvidCookie = req ? req.cookies : cookieStore.getAll();

  if (spvidCookie && typeof spvidCookie === 'object') {
    for (const [key, value] of Object.entries(spvidCookie)) {
      if (key.startsWith(spCookie)) {
        const cookieValue = value as string;
        const split = cookieValue?.split('.') || [];
        return {
          domainUserId: split[0],
          sessionId: split[5],
        };
      }
    }
  }

  return {};
};

const getPaginationContextData = (context: any): IPaginationContext => {
  const { currentPage, pageSize, totalResults } = context.pagination;
  const paginationContextData = {
    current_page: currentPage,
    page_size: pageSize,
    total_results: totalResults,
  };
  return paginationContextData;
};

const getFilterSortContextData = (context: any): IFilterSortContext => {
  const { filters, sortEventValue } = context;
  const formattedFilters = filters.map((data: { category: string; value: string }) => ({
    category: normalizeStringValueForSnowplow(data.category),
    value: normalizeStringValueForSnowplow(data.value),
  }));
  return {
    filter_by: formattedFilters,
    sort_by: sortEventValue.replace(':', '').toLowerCase().replaceAll(' ', '-'),
  };
};

export const trackCartChanges = (context: any, globalContextFields: IGlobalContextFields) => {
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_CLEAR,
      [ContextKey.Product]: null,
    },
    { ...globalContextFields }
  );
  trackSnowplow(
    {
      event: EVENT_NAMES.AD_CLEAR,
      [ContextKey.Ad]: null,
    },
    { ...globalContextFields }
  );

  //snowplow events include add_to_cart, increase_quantity, decrease_quantity, remove_from_cart
  trackSnowplow(
    {
      event: context.event,
      [ContextKey.Product]: getProductContextData(context),
      [ContextKey.Ad]: getAdContextData(context),
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },
    { ...globalContextFields }
  );
};

// This function handles the modal event specifically for waiting room
export const trackModalView = (context: any, globalContextFields: IGlobalContextFields) => {
  trackSnowplow(
    {
      event: EVENT_NAMES.MODAL_VIEW,
      [ContextKey.Modal]: {
        name: 'waiting-room-end',
        id: 'waiting-room-end',
      },
    },
    { ...globalContextFields }
  );
};

export const trackProductCarousel = (context: any, globalContextFields: IGlobalContextFields) => {
  const { event } = context;
  //only track carousel events for the same component id once
  if (!hasExistingEvent(context.event, 'component.id', context.component.id)) {
    trackSnowplow(
      {
        event: EVENT_NAMES.COMPONENT_CLEAR,
        [ContextKey.Product]: null,
        [ContextKey.Component]: null,
      },
      { ...globalContextFields }
    );

    //snowplow events include: carousel_load,  carousel_view
    trackSnowplow(
      {
        event,
        [ContextKey.Product]: getProductsArrayContextData(context),
        [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
      },
      { ...globalContextFields }
    );
  }
};

export const trackProduct = (context: any, globalContextFields: IGlobalContextFields) => {
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.Product]: null,
      [ContextKey.Component]: null,
    },
    { ...globalContextFields }
  );

  //snowplow events include: product_click,
  trackSnowplow(
    {
      event: context.event,
      [ContextKey.Product]: getProductsArrayContextData(context),
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },
    { ...globalContextFields }
  );
};

export const trackAdEvents = (context: any, globalContextFields: IGlobalContextFields) => {
  // if type received from helios component is product_click but it is not sponsored product skip ad events
  if (!context.ad) {
    return;
  }
  //only track Ad events for the same creative_id once
  if (!hasExistingEvent(context.event, 'ad.creative_id', context.ad.creative_id)) {
    //ad_clear event is pushed to analyticsLayer to null any previous ad parameter
    trackSnowplow(
      {
        event: EVENT_NAMES.AD_CLEAR,
        [ContextKey.Ad]: null,
      },
      { ...globalContextFields }
    );
    //ad snowplow events include: ad_load, ad_view, ad_click
    trackSnowplow(
      {
        event: context.event,
        [ContextKey.Ad]: getAdContextData(context),
        [ContextKey.Product]: getAdProductContextData(context),
      },
      { ...globalContextFields }
    );
  }
};

export const trackNavClicks = (context: any, globalContextFields: IGlobalContextFields) => {
  //component_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.Component]: null,
      custom_engagement: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EVENT_NAMES.UI_ENGAGEMENT,
      custom_engagement: {
        action: 'click',
      },
      [ContextKey.Component]: getComponentContextData(context, globalContextFields.pageId),
    },

    { ...globalContextFields }
  );
};

export const getUserEventComponentData = (context: any, pageId: string) => {
  const { id, name, type, click_action, click_element, placement } = context.component || {};
  const componentContextData = {
    id: id || null,
    name: name || null,
    type: type || null,
    //use page_name value if placement is not provided
    placement: placement || mapPageIdToPageTemplate(pageId) || mapPageIdToPageName[pageId] || pageId,
    click_action: click_action || null,
    click_element: click_element || null,
  };
  return componentContextData;
};

export const trackUserClicks = (context: any, globalContextFields: IGlobalContextFields) => {
  // only for tracking user click events, does not include component_view
  if (context.event === EVENT_NAMES.UI_ENGAGEMENT) {
    //component_clear event is pushed to analyticsLayer to null any previous parameter
    trackSnowplow(
      {
        event: EVENT_NAMES.COMPONENT_CLEAR,
        [ContextKey.Component]: null,
        custom_engagement: null,
      },
      { ...globalContextFields }
    );

    trackSnowplow(
      {
        event: EVENT_NAMES.UI_ENGAGEMENT,
        custom_engagement: {
          action: 'click',
        },
        [ContextKey.Component]: getUserEventComponentData(context, globalContextFields.pageId),
      },

      { ...globalContextFields }
    );
  }
};

export const trackPage = (context: any, globalContextFields: IGlobalContextFields) => {
  // product_pagination_clear event is pushed to analytics layer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_PAGINATION_CLEAR,
      [ContextKey.Product]: null,
      pagination: null,
    },
    { ...globalContextFields }
  );

  // snowplow event for product_listing
  trackSnowplow(
    {
      event: EVENT_NAMES.PRODUCT_LISTING,
      [ContextKey.Product]: getProductsArrayContextData(context),
      [ContextKey.Pagination]: getPaginationContextData(context),
    },
    { ...globalContextFields }
  );
};

export const trackSort = (context: any, globalContextFields: IGlobalContextFields) => {
  const filterSortData = {
    filter_by: null,
    sort_by: context.sortEventValue.replace(':', '').toLowerCase().replaceAll(' ', '-'),
  };

  if (!hasExistingEvent(EventName.ApplyFilter, 'filter_sort', filterSortData)) {
    trackSnowplow(
      {
        event: EVENT_NAMES.FILTER_SORT_CLEAR,
        [ContextKey.FilterSort]: null,
      },
      { ...globalContextFields }
    );

    trackSnowplow(
      {
        event: EventName.ApplySort,
        [ContextKey.FilterSort]: filterSortData,
      },
      { ...globalContextFields }
    );
  }
};

export const trackFilter = (context: any, globalContextFields: IGlobalContextFields) => {
  const formattedFilters = context.trackFilterData.map((data: { category: string; value: string }) => ({
    category: normalizeStringValueForSnowplow(data.category),
    value: normalizeStringValueForSnowplow(data.value),
  }));
  //filter_sort_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.FILTER_SORT_CLEAR,
      [ContextKey.FilterSort]: null,
      custom_engagement: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EventName.ApplyFilter,
      [ContextKey.FilterSort]: {
        filter_by: formattedFilters,
        sort_by: null,
      },
    },

    { ...globalContextFields }
  );
};

export const trackProductSearch = (context: any, globalContextFields: IGlobalContextFields) => {
  trackSnowplow(
    {
      event: EVENT_NAMES.SEARCH_CLEAR,
      [ContextKey.Search]: null,
      [ContextKey.Product]: null,
      [ContextKey.DsModel]: null,
      [ContextKey.Pagination]: null,
      [ContextKey.FilterSort]: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EventName.ProductSearch,
      [ContextKey.Search]: {
        search_request_id: '',
        search_terms: context.search.searchTerms,
        search_type: context.search.searchType || 'product',
        typed_search_terms: context.search.searchTerms,
        search_results_count: context.search.totalSearchResults,
        search_terms_type: 'manual-search',
        search_feature: 'organic',
      },
      [ContextKey.Product]: getProductsArrayContextData(context),
      [ContextKey.Pagination]: getPaginationContextData(context),
      [ContextKey.FilterSort]: getFilterSortContextData(context),
      [ContextKey.DsModel]: context.dsModel?.find((model: any) => model.type == 'searchVariation'),
    },
    { ...globalContextFields }
  );
};

export const getProductSearchRedirectContext = (searchRedirectData: any, viewDefinition: any) => {
  const { searchTerm, title = '' } = searchRedirectData;
  return {
    search: {
      searchTerms: searchTerm?.[0] || title,
      searchType: 'redirect',
      totalSearchResults: 0,
    },
    pagination: {
      currentPage: null,
      pageSize: null,
      totalResults: null,
    },
    products: [],
    filters: [],
    sortEventValue: '',
    dsModel: [
      {
        type: 'searchVariation',
        value: viewDefinition?.searchVariation || '',
      },
    ],
  };
};

export const trackForm = (context: any, globalContextFields: IGlobalContextFields) => {
  const { name, id, type, submission_id } = context.trackFormData || {};
  // for when API responses do not return submission_id
  const domainUserId = getSnowplowUserData()?.domainUserId || '';
  //component_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.Component]: null,
      form: null,
    },
    { ...globalContextFields }
  );
  trackSnowplow(
    {
      event: EventName.FormSubmit,
      [ContextKey.Form]: { name, id, submission_id: submission_id || `${domainUserId}-${Date.now()}` },
      [ContextKey.Component]: { type, name, id },
    },
    { ...globalContextFields }
  );
};

export const trackException = (context: any, globalContextFields: IGlobalContextFields) => {
  const { type, message, code, user_informed, process } = context.trackExceptionData ?? {};
  trackSnowplow(
    {
      event: 'exception_clear',
      modal: null,
    },
    { ...globalContextFields }
  );
  trackSnowplow(
    {
      event: EventName.Exception,
      [ContextKey.Exception]: {
        type,
        message,
        code,
        process,
        user_informed,
      },
    },
    { ...globalContextFields }
  );
};

export const trackEvent = (type: string, context: any, snowplowGlobalContextFields: IGlobalContextFields) => {
  switch (type) {
    case WAITING_ROOM_MODAL_VIEW:
      trackModalView(context, snowplowGlobalContextFields);
      break;
    case HELIOS_ADD_TO_CART:
      trackCartChanges(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PRODUCT_CAROUSEL:
      trackProductCarousel(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PRODUCT_EVENT:
      trackProduct(context, snowplowGlobalContextFields);
      break;
    case HELIOS_AD_TRACKING:
    case PRODUCT_CLICK:
      trackAdEvents(context, snowplowGlobalContextFields);
      break;
    case HELIOS_NAV_CLICK:
      trackNavClicks(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PAGE_TRACKING:
      trackPage(context, snowplowGlobalContextFields);
      break;
    case HELIOS_SORT_TRACKING:
      trackSort(context, snowplowGlobalContextFields);
      break;
    case HELIOS_FILTER_TRACKING:
      trackFilter(context, snowplowGlobalContextFields);
      break;
    case HELIOS_PRODUCT_SEARCH_TRACKING:
      trackProductSearch(context, snowplowGlobalContextFields);
      break;
    case HELIOS_HORIZONTAL_FEATURE_CARDS:
    case HELIOS_BANNER_CAROUSEL:
      trackUserClicks(context, snowplowGlobalContextFields);
      break;
    case HELIOS_FORM_TRACKING:
    case HELIOS_ACQUISITION_FORM_SUBMIT:
      trackForm(context, snowplowGlobalContextFields);
      break;
    case HELIOS_EXCEPTION_TRACKING:
      trackException(context, snowplowGlobalContextFields);
      break;
    default:
      return;
  }
};

export const trackRender = (componentName: string) => {
  switch (componentName) {
    case HELIOS_REFRESH_LINKS_CLICK_TRACKING:
      snowplow?.refreshLinkClickTracking();
      break;
    default:
      return;
  }
};

/** callback function to help ensure ad_click is fired before redirection occurs on sponsored tile click */
export const trackEventTileAdClick = (
  componentName: string,
  { isSponsored, productId }: { isSponsored: boolean; productId: string }
) => {
  /** condition for sponsored product tiles used in product grid component  */
  if (componentName === HELIOS_COMPONENT_NAMES.PRODUCT_GRID_COMPONENT && isSponsored && productId) {
    const analyticsLayer = window[dataLayerName];

    const adClickEntry: any = analyticsLayer?.filter((entry: any) => {
      /** check to see if ad_click event for the specific product is captured in analyticsLayer */
      if (entry.ad) {
        return entry.event === 'ad_click' && entry.ad.product_article_number === productId;
      }
      return;
    });

    /** ad_click event captured for this product tile, we may proceed with redirection */
    if (adClickEntry.length > 0) {
      return;
    } else {
      /** delay for half a second to let snowplow ad_click event finish */
      setTimeout(() => {}, 500);
    }
  }
  return;
};

export const trackSearchStore = (globalContextFields: IGlobalContextFields, clickElement: string) => {
  //component_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.COMPONENT_CLEAR,
      [ContextKey.CustomEngagement]: null,
      [ContextKey.Component]: null,
      [ContextKey.ShoppingExperience]: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EVENT_NAMES.UI_ENGAGEMENT,
      [ContextKey.CustomEngagement]: {
        action: 'submit',
      },
      [ContextKey.Component]: {
        id: PAGE_ID.STORE_LOCATOR,
        name: PAGE_ID.STORE_LOCATOR,
        type: 'search-bar',
        click_element: clickElement,
        click_action: 'find-store',
      },
    },

    { ...globalContextFields }
  );
};

export const trackApplyFilter = (globalContextFields: IGlobalContextFields, formattedData: any) => {
  //component_clear event is pushed to analyticsLayer to null any previous parameter
  trackSnowplow(
    {
      event: EVENT_NAMES.FILTER_SORT_CLEAR,
      [ContextKey.FilterSort]: null,
      [ContextKey.ShoppingExperience]: null,
    },
    { ...globalContextFields }
  );

  trackSnowplow(
    {
      event: EVENT_NAMES.APPLY_FILTER,
      [ContextKey.FilterSort]: {
        filter_by: formattedData,
        sort_by: null, //required field, set value as "null"
      },
    },

    { ...globalContextFields }
  );
};
