import { FC } from 'react';
import { assetsPath } from 'Data/constants';
import { ISpinnerProps } from './spinnder.interface';
import styles from './spinner.module.scss';

export const Spinner: FC<ISpinnerProps> = ({ width = 64, height = 64, style = {} }) => (
  <img
    src={`${assetsPath}/site-media/spinner.gif`}
    width={width}
    height={height}
    alt="Spinner loading"
    className={styles.loader}
    style={style}
  />
);
