import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ICombinedStore } from './store.interface';
import { cartDataSlice } from './cartData';
import { kamFlagDataSlice } from './kameleoonFlags';
import { viewDefinitionSlice } from './viewDefinition';
import { headerFooterSlice } from './headerFooter';

export const useStore = create<ICombinedStore>()(
  devtools(
    (...a) => ({
      ...cartDataSlice(...a),
      ...viewDefinitionSlice(...a),
      ...headerFooterSlice(...a),
      ...kamFlagDataSlice(...a),
    }),
    {
      name: 'storage',
    }
  )
);
