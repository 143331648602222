import { cookies } from '@/data/constants';
import { isPharmaprixBanner } from '@/utils';
import { getSiteId, isLoggedIn } from '@/utils/auth';
import { HTTPError } from '@/utils/error';
import type { GetServerSidePropsContext, PreviewData } from 'next';
import type { ParsedUrlQuery } from 'querystring';

const getStatusOrGuid = (status: string, guid: string) => {
  return status === 'anonymous' ? guid : status;
};

const getData = async (guid: string, status: string, ciamAccessToken: string, banner: string) => {
  const request: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-apikey': process.env.NEXT_PUBLIC_BFF_API_KEY ?? '',
      Authorization: ciamAccessToken ? `${ciamAccessToken}` : '',
    },
  };

  const param = getStatusOrGuid(status, guid);
  if (guid || ciamAccessToken) {
    const url = `${process.env.NEXT_PUBLIC_BFF_URL}/${banner}/users/${status}/carts/${param}?loyaltyInfo=simple&=`;
    return fetch(url, request)
      .then(async (response) => {
        if (!response.ok) {
          throw new HTTPError(response.status, await response.text(), { cause: { request, response } });
        }

        return response;
      })
      .then((val) => val.json());
  }
};
/** @throws {HTTPError} if the the http response is not ok */
export const getCartData = async (context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): Promise<any> => {
  const isPharmaprix = isPharmaprixBanner(context?.req);
  const guid = context?.req?.cookies?.[
    isPharmaprix ? cookies.PHARMAPRIX_CART_HELIOS : cookies.SHOPPERSDRUGMART_CART_HELIOS
  ] as string;
  const banner = getSiteId(context.req);
  const ciamAccessToken = isLoggedIn(context?.req);
  const status = ciamAccessToken ? 'current' : 'anonymous';
  const data = (await getData(guid, status, ciamAccessToken, banner)) ?? null;

  return { data };
};
