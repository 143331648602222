import { resourceAttributes } from '../constants/setup';
import { SITE_ID } from 'Data/constants';
import { isPharmaprixBanner } from '@/utils';

const getExtendedAttributes = (path: string, userId: string) => {
  const isBannerPharma = typeof window !== 'undefined' ? isPharmaprixBanner() : false;
  return {
    ...getResourceAttributes(isBannerPharma),
    'ld.path': path,
    'ld.user.id': userId,
  };
};

export const getResourceAttributes = (isBannerPharma?: boolean) => {
  const banner = isBannerPharma ? SITE_ID.PHX : SITE_ID.SDM;

  return {
    ...resourceAttributes,
    'ld.banner': banner,
  };
};

export { getExtendedAttributes };
