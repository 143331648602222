import { useEffect } from 'react';
import { Product } from './firework-analytics.interface';
import { formatProductData } from './utils';

/**
 * Hook that injects the Firework analytics script
 */
export const useFireworkAnalytics = (): void => {
  useEffect(() => {
    const scriptId = 'firework-analytics-script';
    if (document.getElementById(scriptId)) return;

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = '//asset.fwcdn3.com/js/analytics.js';
    script.className = 'optanon-category-C0002';
    script.type = 'text/plain'; // text/plain default type for Quebec CCM

    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);
};

/**
 * Checks if Firework analytics is available in the window object
 */
const isFireworkAnalyticsAvailable = (): boolean => !!window._fwn?.analytics;

const formatFireworkEvent = (product: Product, quantity: number) => ({
  currency: product.price.currencyIso,
  subtotal: product.price.numericalPrice * quantity,
  product: [
    {
      ext_parent_product_id: product.id,
      quantity,
      price: product.price.numericalPrice,
    },
  ],
});

/**
 * Tracks an add to cart event in Firework analytics
 */
export const fireworkTrackAddToCart = (
  rawProduct: { code: string; price: { currencyIso: string; value: number } },
  quantity: number
): void => {
  try {
    if (!isFireworkAnalyticsAvailable()) return;
    const product = formatProductData(rawProduct);
    window._fwn?.analytics.addToCart(formatFireworkEvent(product, quantity));
  } catch (error) {
    console.warn('Failed to track add to cart event:', error);
  }
};

/**
 * Tracks a remove from cart event in Firework analytics
 */
export const fireworkTrackRemoveFromCart = (
  rawProduct: { code: string; price: { currencyIso: string; value: number } },
  quantity: number
): void => {
  try {
    if (!isFireworkAnalyticsAvailable()) return;
    const product = formatProductData(rawProduct);
    window._fwn?.analytics.removeFromCart(formatFireworkEvent(product, Math.abs(quantity)));
  } catch (error) {
    console.warn('Failed to track remove from cart event:', error);
  }
};

/**
 * Tracks a page view event in Firework analytics
 */
export const fireworkTrackPageView = (pageType = 'other'): void => {
  try {
    if (!isFireworkAnalyticsAvailable()) return;

    window._fwn?.analytics.pageViewed({
      page_type: pageType,
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  } catch (error) {
    console.warn('Failed to track page view event:', error);
  }
};
