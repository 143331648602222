import client from 'Api/datasources/bff-datasources';

/**
 * Response type for product hydration data
 */
interface ProductHydrationResponse {
  products: Array<{
    id: string;
    name: string;
    description: string;
    price: {
      value: number;
      currencyIso: string;
    };
    specialPrice?: {
      value: number;
      currencyIso: string;
    };
    images: {
      thumbnail: string;
    };
    url: string;
    isAvailable: boolean;
  }>;
}

/**
 * Fetches product details for Firework hydration
 * @param productIds - Array of product IDs to fetch
 * @returns Promise containing hydrated product details
 */
export const fetchFireworkProducts = async (
  productIds: string[]
): Promise<{ products: Record<string, ProductHydrationResponse['products'][0]> }> => {
  try {
    const queryString = `productIds=${productIds.join(',').toUpperCase()}`;
    const response = await client.get<ProductHydrationResponse>(`/products/hydrate?${queryString}`, {});

    // Transform array to object with id as key
    const products = response.data.products.reduce<Record<string, ProductHydrationResponse['products'][0]>>(
      (acc, product) => {
        acc[product.id] = product;
        return acc;
      },
      {}
    );

    return { products };
  } catch (error) {
    console.error('Failed to fetch product details:', error);

    return { products: {} };
  }
};
