import {
  InstrumentationBase,
  InstrumentationConfig,
  isWrapped,
  safeExecuteInTheMiddle,
} from '@opentelemetry/instrumentation';
import { context, diag, Exception, HrTime, Span, SpanKind, SpanStatusCode, trace } from '@opentelemetry/api';
import { _globalThis, hrTime, hrTimeToNanoseconds, isUrlIgnored, millisToHrTime } from '@opentelemetry/core';
import {
  addSpanNetworkEvents,
  getResource,
  parseUrl,
  PerformanceTimingNames,
  shouldPropagateTraceHeaders,
} from '@opentelemetry/sdk-trace-web';
import {
  SEMATTRS_HTTP_HOST,
  SEMATTRS_HTTP_METHOD,
  SEMATTRS_HTTP_REQUEST_CONTENT_LENGTH_UNCOMPRESSED,
  SEMATTRS_HTTP_SCHEME,
  SEMATTRS_HTTP_STATUS_CODE,
  SEMATTRS_HTTP_URL,
  SEMATTRS_HTTP_USER_AGENT,
} from '@opentelemetry/semantic-conventions';

import { Event } from '../types/event';
import { FetchError, FetchResponse, SpanData } from '../types/fetch';
import { getTraceCarrier, tracer } from '../utils/traces';
import { getFetchBodyLength, normalizeHeaders } from '../utils/fetch';
import { TraceCarrier } from '../types/setup';

const otelLogger = diag.createComponentLogger({
  namespace: '✨ @telemetry-sdk/instrumentation/fetch',
});
const OBSERVER_WAIT_TIME_MS = 300;

interface CustomFetchInstrumentationConfig extends InstrumentationConfig {
  options: {
    /**
     * The number of timing resources is limited, after the limit
     * (chrome 250, safari 150) the information is not collected anymore
     * the only way to prevent that is to regularly clean the resources
     * whenever it is possible, this is needed only when PerformanceObserver
     * is not available
     */
    clearTimingResources?: boolean;
    /** Ignore adding network events as span events **/
    ignoreNetworkEvents?: boolean;
    /** Measure outgoing request size */
    measureRequestSize?: boolean;
  };
  urls?: {
    /** URLs which should include trace headers when origin doesn't match **/
    propagatedUrls?: Array<string | RegExp>;
    /**
     * URLs that partially match any regex in ignoreUrls will not be traced.
     * In addition, URLs that are _exact matches_ of strings in ignoreUrls will
     * also not be traced.
     */
    ignoredUrls?: Array<string | RegExp>;
  };
  hooks?: {
    /** Function for calling event service after response is handled*/
    onFetchEvent?: (event: Event) => void;
  };
}

class CustomFetchInstrumentation extends InstrumentationBase<CustomFetchInstrumentationConfig> {
  readonly component: string = 'fetch';
  moduleName = this.component;
  private _usedResources = new WeakSet<PerformanceResourceTiming>();
  private _tasksCount = 0;

  constructor(config: CustomFetchInstrumentationConfig) {
    super('CustomFetchInstrumentation', '1.0.0', config);
  }

  // resource management
  private _markResourceAsUsed(resource: PerformanceResourceTiming): void {
    this._usedResources.add(resource);
  }

  private _clearResources() {
    if (this._tasksCount === 0 && this.getConfig().options.clearTimingResources) {
      performance.clearResourceTimings();
      this._usedResources = new WeakSet<PerformanceResourceTiming>();
    }
  }

  // span network events and children
  private _addSpanResponseAttributes(span: Span, response: FetchResponse): void {
    const parsedUrl = parseUrl(response.url);
    span.setAttribute(SEMATTRS_HTTP_STATUS_CODE, response.status);
    if (response.statusText != null) {
      span.setAttribute('http.status_text', response.statusText);
    }
    span.setAttribute(SEMATTRS_HTTP_HOST, parsedUrl.host);
    span.setAttribute(SEMATTRS_HTTP_SCHEME, parsedUrl.protocol.replace(':', ''));
    if (typeof navigator !== 'undefined') {
      span.setAttribute(SEMATTRS_HTTP_USER_AGENT, navigator.userAgent);
    }
  }

  private _addSpanNetworkChildren(span: Span, corsPreFlightRequest: PerformanceResourceTiming): void {
    const childSpan = this.tracer.startSpan(
      'CORS Preflight',
      {
        startTime: corsPreFlightRequest[PerformanceTimingNames.FETCH_START],
      },
      trace.setSpan(context.active(), span)
    );
    addSpanNetworkEvents(childSpan, corsPreFlightRequest, this.getConfig().options.ignoreNetworkEvents);
    childSpan.end(corsPreFlightRequest[PerformanceTimingNames.RESPONSE_END]);
  }

  private _addSpanNetworkEvents(span: Span, spanData: SpanData, endTime: HrTime): void {
    let resources: PerformanceResourceTiming[] = spanData.entries;
    if (!resources.length) {
      if (!performance.getEntriesByType) {
        return;
      }
      // fallback - either Observer is not available or it took longer
      // then OBSERVER_WAIT_TIME_MS and observer didn't collect enough
      // information
      resources = performance.getEntriesByType('resource') as PerformanceResourceTiming[];
    }
    const resource = getResource(spanData.url, spanData.startTime, endTime, resources, this._usedResources, 'fetch');

    if (resource.mainRequest) {
      const mainRequest = resource.mainRequest;
      this._markResourceAsUsed(mainRequest);

      const corsPreFlightRequest = resource.corsPreFlightRequest;
      if (corsPreFlightRequest) {
        this._addSpanNetworkChildren(span, corsPreFlightRequest);
        this._markResourceAsUsed(corsPreFlightRequest);
      }
      addSpanNetworkEvents(span, mainRequest, this.getConfig().options.ignoreNetworkEvents);
    }
  }

  // span lifecycle
  private _startSpan(
    url: string,
    request: Partial<Request | RequestInit> = {},
    bodyLength: number | undefined
  ): Span | undefined {
    try {
      const method = (request.method || 'GET').toUpperCase();
      const spanName = `HTTP ${method}`;
      const windowContext = typeof window !== 'undefined' ? window.rootContext : undefined;
      const rootContext = windowContext || context.active();
      const span = tracer().startSpan(
        spanName,
        {
          kind: SpanKind.CLIENT,
          attributes: {
            ['component']: this.moduleName,
            [SEMATTRS_HTTP_METHOD]: method,
            [SEMATTRS_HTTP_URL]: url,
          },
        },
        rootContext
      );
      if (this.getConfig().options.measureRequestSize && bodyLength)
        span.setAttribute(SEMATTRS_HTTP_REQUEST_CONTENT_LENGTH_UNCOMPRESSED, bodyLength);
      this._tasksCount++;

      return span;
    } catch (err) {
      otelLogger.error('Error in startSpan:', err);
    }
  }

  private _getSpanData(url: string): SpanData {
    const startTime = hrTime();
    const entries: PerformanceResourceTiming[] = [];
    if (typeof PerformanceObserver !== 'function') {
      return { url, startTime, entries };
    }
    const observer = new PerformanceObserver((list) => {
      const perfObsEntries = list.getEntries() as PerformanceResourceTiming[];
      perfObsEntries.forEach((entry) => {
        if (entry.initiatorType === 'fetch' && entry.name === url) {
          entries.push(entry);
        }
      });
    });
    observer.observe({
      entryTypes: ['resource'],
    });
    return { url, startTime, entries, observer };
  }

  private _endSpan(span: Span, spanData: SpanData, response: FetchResponse) {
    const endTime = millisToHrTime(Date.now());
    const performanceEndTime = hrTime();
    this._addSpanResponseAttributes(span, response);

    setTimeout(() => {
      spanData.observer?.disconnect();
      this._addSpanNetworkEvents(span, spanData, performanceEndTime);
      this._tasksCount--;
      this._clearResources();
      span.end(endTime);
    }, OBSERVER_WAIT_TIME_MS);
  }

  // headers
  private _injectRequestHeaders(
    url: string,
    request: Request | RequestInit,
    traceCarrier: TraceCarrier,
    isPropagatedUrl: boolean
  ) {
    if (!isPropagatedUrl) {
      otelLogger.debug('request headers inject skipped due to CORS policy');
      return request;
    }

    /**
     * Keep in mind:
     * - Cloning a Request creates a new Request object with all original properties.
     * - The Request body is a ReadableStream (one-time use). Avoid reading or spreading the Request object to prevent:
     *   - TypeError: "Failed to execute 'fetch' on 'Window': Cannot construct a Request with a used Request object."
     * - Creating a new Request manually might lose HTTP/2 properties, leading to H2/QUIC errors.
     *   - Even if you manually set all the same properties, the Request object does not expose the HTTP2 specific ones.
     *   - The same applies to Response objects.
     *
     * Solution:
     * - Clone the Request and update headers using headers.set() to avoid overwriting the entire headers object.
     * - Fallback to creating a new Request if headers.set() fails.
     */

    if (request instanceof Request) {
      try {
        // attempt writing directly to request headers
        const newRequest = request.clone();
        Object.entries(traceCarrier).forEach(([key, val]) => {
          newRequest.headers.set(key, val);
        });
        return newRequest;
      } catch (err) {
        // otherwise, create a new request
        const reqClone = request.clone();
        const newHeaders = normalizeHeaders(reqClone.headers);
        Object.entries(traceCarrier).forEach(([k, v]) => {
          newHeaders[k] = v;
        });
        const newRequest = new Request(url, {
          // cannot use spread operator, it will mark body as bodyUsed: true
          // note this does not inherit http2 properties of the original request
          ...(reqClone.method && { method: reqClone.method }),
          ...(reqClone.referrer && { referrer: reqClone.referrer }),
          ...(reqClone.referrerPolicy && { referrerPolicy: reqClone.referrerPolicy }),
          ...(reqClone.mode && { mode: reqClone.mode }),
          ...(reqClone.credentials && { credentials: reqClone.credentials }),
          ...(reqClone.cache && { cache: reqClone.cache }),
          ...(reqClone.redirect && { redirect: reqClone.redirect }),
          ...(reqClone.integrity && { integrity: reqClone.integrity }),
          ...(reqClone.duplex && { duplex: reqClone.duplex }),
          body: reqClone.body,
          headers: newHeaders,
        });
        return newRequest;
      }
    }

    const newRequestInit = { ...request, headers: normalizeHeaders(request.headers) };
    Object.entries(traceCarrier).forEach(([key, val]) => {
      newRequestInit.headers[key] = val;
    });
    return newRequestInit;
  }

  private _injectResponseHeaders(response: Response, traceCarrier: TraceCarrier, isPropagatedUrl: boolean) {
    if (!isPropagatedUrl) {
      otelLogger.debug('response headers inject skipped due to CORS policy');
      return response;
    }

    try {
      // attempt writing directly to response headers
      const newResponse = response.clone();
      Object.entries(traceCarrier).forEach(([key, val]) => {
        newResponse.headers.set(key, val);
      });
      return newResponse;
    } catch (err) {
      // otherwise, create a new response
      const resClone = response.clone();
      const oldHeaders = normalizeHeaders(resClone.headers);
      const newHeaders = { ...oldHeaders, ...traceCarrier };
      const newResponse = new Response(resClone.body, {
        // cannot use spread operator, it will mark body as bodyUsed: true
        status: resClone.status,
        statusText: resClone.statusText,
        headers: newHeaders,
      });
      return newResponse;
    }
  }

  private _readResponseStream(response: Response, onSuccess: () => void, onError: (error: unknown) => void) {
    const spanResClone = response.clone();
    if (spanResClone.body) {
      const reader = spanResClone.body.getReader();
      const read = (): void => {
        reader.read().then(
          ({ done }) => {
            if (done) {
              onSuccess();
            } else {
              read();
            }
          },
          (error: unknown) => {
            onError(error);
          }
        );
      };
      read();
    } else {
      onSuccess();
    }
  }

  // events
  private _callEventHook(startTime: HrTime, endTime: HrTime, isFetchError: boolean = false) {
    try {
      const startTimeNs = hrTimeToNanoseconds(startTime);
      const endTimeNs = hrTimeToNanoseconds(endTime);
      const event: Event = {
        type: 'fetch',
        startTime: startTimeNs,
        endTime: endTimeNs,
        duration: endTimeNs - startTimeNs,
        isError: isFetchError,
      };
      safeExecuteInTheMiddle(
        () => {
          this.getConfig().hooks?.onFetchEvent?.(event);
        },
        (error) => {
          if (error) otelLogger.error('requestHook', error);
        },
        true
      );
    } catch (hookError) {
      otelLogger.error('Error in callHook:', hookError);
    }
  }

  private _patchConstructor(): (original: typeof fetch) => typeof fetch {
    return (original) => {
      const plugin = this;
      return async function patchConstructor(
        this: typeof globalThis,
        ...args: Parameters<typeof fetch>
      ): Promise<Response> {
        const self = this;

        // simplify options
        const url = parseUrl(args[0] instanceof Request ? args[0].url : String(args[0])).href;
        const request = args[0] instanceof Request ? args[0] : args[1] || {};

        // check urls
        const isIgnoredUrl = isUrlIgnored(url, plugin.getConfig().urls?.ignoredUrls);
        const isPropagatedUrl = shouldPropagateTraceHeaders(url, plugin.getConfig().urls?.propagatedUrls);
        const bodyLength = await getFetchBodyLength(plugin.getConfig().options.measureRequestSize, ...args);
        if (isIgnoredUrl) return original.apply(self, args);

        // create span
        const span = plugin._startSpan(url, request, bodyLength);
        const spanData = plugin._getSpanData(url);
        if (!span) return original.apply(self, args);

        // add request headers
        const traceCarrier = getTraceCarrier(span);
        const newRequest = plugin._injectRequestHeaders(url, request, traceCarrier, isPropagatedUrl);

        return new Promise((resolve, reject) => {
          return context.with(trace.setSpan(context.active(), span), () => {
            return original.apply(self, newRequest instanceof Request ? [newRequest] : [url, newRequest]).then(
              function onSuccess(response: Response) {
                try {
                  // call event hook
                  plugin._callEventHook(spanData.startTime, hrTime(), false);

                  // add response headers
                  const newResponse = plugin._injectResponseHeaders(response, traceCarrier, isPropagatedUrl);

                  // end span on end of stream
                  plugin._readResponseStream(
                    response,
                    function onEnd() {
                      plugin._endSpan(span, spanData, {
                        status: response.status,
                        statusText: response.statusText,
                        url,
                      });
                    },
                    function onError(error: unknown) {
                      plugin._endSpan(span, spanData, {
                        status: (error as FetchError).status || 0,
                        statusText: (error as FetchError).message,
                        url,
                      });
                    }
                  );

                  // resolve response
                  resolve(newResponse);
                } catch (error) {
                  // log error
                  otelLogger.error('Could not inject response headers or end success span', error);

                  // resolve response
                  resolve(response);
                }
              },
              function onError(error: FetchError) {
                try {
                  // call event hook
                  plugin._callEventHook(spanData.startTime, hrTime(), true);

                  // end span
                  span.recordException(error as Exception);
                  span.setStatus({
                    code: SpanStatusCode.ERROR,
                    message: error.message,
                  });
                  plugin._endSpan(span, spanData, {
                    status: error.status || 0,
                    statusText: error.message,
                    url,
                  });
                } catch (error) {
                  // log error
                  otelLogger.error('Could not end error span', error);
                } finally {
                  // reject error
                  reject(error);
                }
              }
            );
          });
        });
      };
    };
  }

  // lifecycle
  protected init(): void {
    // there are no init actions required
  }

  override enable(): void {
    try {
      if (isWrapped(fetch)) {
        this._unwrap(_globalThis, 'fetch');
        otelLogger.debug('removing previous patch for constructor');
      }
      this._wrap(_globalThis, 'fetch', this._patchConstructor());
    } catch (error) {
      otelLogger.error('Error in instrumentation enable:', error);
    }
  }

  override disable(): void {
    try {
      this._unwrap(_globalThis, 'fetch');
      this._usedResources = new WeakSet<PerformanceResourceTiming>();
    } catch (error) {
      otelLogger.error('Error in instrumentation disable:', error);
    }
  }
}

export { CustomFetchInstrumentation };
