import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fireworkTrackAddToCart, fireworkTrackRemoveFromCart } from '@/utils/firework/firework-analytics';

import { QUERY_KEYS } from 'Api/constants';
import client from 'Api/datasources/bff-datasources';
import { getExistingCartGuid, getLoggedInStatus, isLoggedIn } from 'Utils/auth';

export const updateCartQuantity = async (productId: any, quantity: any) => {
  const userStatus = () => getLoggedInStatus().toLowerCase();
  const cartUrlParam = isLoggedIn() ? userStatus() : getExistingCartGuid();
  const cartUrl = `/users/${userStatus()}/carts/${cartUrlParam}`;

  if (quantity) {
    const response = await client.patch(`${cartUrl}/entries?productCode=${productId}&qty=${quantity}`, {});
    const { entry, quantityAdded } = response?.data || {};
    const { product } = entry || {};

    if (product) {
      if (quantityAdded > 0) {
        fireworkTrackAddToCart(product, quantityAdded);
      } else if (quantityAdded < 0) {
        fireworkTrackRemoveFromCart(product, quantityAdded);
      }
    }

    return { success: response.status === 200 };
  } else {
    // Get cart data first to find the product being removed
    const cartResponse = await client.get(`${cartUrl}`);
    const productEntry = cartResponse?.data?.entries?.find(
      (e: { product: { code: string } }) => e.product.code === productId
    );

    // Store product info before deletion
    if (productEntry) {
      const product = {
        code: productEntry.product.code,
        price: {
          currencyIso: productEntry.product.price?.currencyIso,
          value: productEntry.product.price?.value,
        },
      };

      // Track removal with current quantity before deleting
      fireworkTrackRemoveFromCart(product, productEntry.quantity);
    }

    // Now perform the delete
    const response = await client.delete(`${cartUrl}/entries?productCode=${productId}`);

    return { success: response.status === 200 };
  }
};

export const useUpdateCartItemQuantity = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateCartItemQuantityMutation,
    isPending: isUpdateCartItemQuantityLoading,
    error: updateCartItemQuantityError,
    data,
  } = useMutation({
    mutationFn: ({ productId, quantity }: { productId: any; quantity: any }) => updateCartQuantity(productId, quantity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CART] });
    },
  });

  const createUpdateCartItemQuantityMutation = (productId: any, quantity: any) =>
    updateCartItemQuantityMutation({ productId, quantity });

  return { createUpdateCartItemQuantityMutation, isUpdateCartItemQuantityLoading, updateCartItemQuantityError, data };
};
