import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { MeterProvider } from '@opentelemetry/sdk-metrics';

interface TelemetryProviders {
  tracerProvider: WebTracerProvider;
  meterProvider: MeterProvider;
}

const setupCSRTelemetryFlush = ({ tracerProvider, meterProvider }: TelemetryProviders) => {
  if (typeof window === 'undefined') return; // Guard for SSR

  const handleBeforeUnload = async () => {
    try {
      // Create a promise that times out after 2 seconds
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Flush timeout')), 2000);
      });

      // Force flush both trace and metrics data
      await Promise.race([Promise.all([tracerProvider.forceFlush(), meterProvider.forceFlush()]), timeoutPromise]);

      // Log success if needed
      console.debug('Successfully flushed OpenTelemetry data');
    } catch (error) {
      // Log error if flush fails
      console.error('Failed to flush OpenTelemetry data:', error);
    }
  };

  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);

  // Return cleanup function
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
};

export { setupCSRTelemetryFlush };
