import { useEffect } from 'react';
import { isAndroidDevice } from '@/utils';

export const useUpdateAppBannerStyles = () => {
  useEffect(() => {
    // if not an android device, skip banner style updates
    if (!isAndroidDevice()) {
      return;
    }

    let observer: MutationObserver | null = null;

    const updateElements = (isAtTop: boolean, isBannerVisible: boolean) => {
      const topBarElement = document.querySelector('div[data-testid="top-bar"]') as HTMLElement | null;
      const spacerElement = document.querySelector('div[data-test-id="Spacer"]') as HTMLElement | null;

      if (topBarElement && spacerElement) {
        const parentElement = topBarElement.parentElement as HTMLElement | null;

        if (parentElement) {
          parentElement.style.position = isAtTop ? 'relative' : '';
          spacerElement.style.height = isBannerVisible ? (isAtTop ? '75px' : '') : '0px';
        }
      }
    };

    const handleMutation = () => {
      const isAtTop = window.scrollY === 0;
      const isBannerVisible = !!document.querySelector('.showSdmAppBanner');
      updateElements(isAtTop, isBannerVisible);
    };

    observer = new MutationObserver(handleMutation);

    observer.observe(document.body, { childList: true, subtree: true });

    const timer = setTimeout(() => {
      handleMutation();
    }, 2000);

    return () => {
      clearTimeout(timer);
      observer?.disconnect();
    };
  }, []);
};
