import { InstrumentationBase, InstrumentationConfig } from '@opentelemetry/instrumentation';
import { context, diag, Span, trace } from '@opentelemetry/api';

import { getTraceContext, tracer } from '../utils/traces';
import { Event } from '../types/event';

const otelLogger = diag.createComponentLogger({
  namespace: '✨ @telemetry-sdk/instrumentation/load',
});

interface CustomLoadInstrumentationConfig extends InstrumentationConfig {
  hooks?: {
    onLoadEvent?: (event: Event) => void;
  };
}

class CustomLoadInstrumentation extends InstrumentationBase<CustomLoadInstrumentationConfig> {
  private fcpObserver?: PerformanceObserver;
  private lcpObserver?: PerformanceObserver;
  private rootSpan?: Span;

  constructor(config: CustomLoadInstrumentationConfig) {
    super('CustomLoadInstrumentation', '1.0.0', config);
  }

  // helpers
  private _getConfig(): CustomLoadInstrumentationConfig {
    return this.getConfig() as CustomLoadInstrumentationConfig;
  }

  private _getNavigationEntry(): PerformanceNavigationTiming | undefined {
    const entries = performance.getEntriesByType('navigation');
    return entries[0] as PerformanceNavigationTiming;
  }

  private _wrapHandler = (
    type: Event['type'],
    fn: (type: Event['type'], navEntry: PerformanceNavigationTiming) => void
  ) => {
    // Timeout is needed as load event doesn't have yet the performance metrics for loadEnd.
    // Support for event "loadend" is very limited and cannot be used
    setTimeout(() => {
      try {
        const navEntry = this._getNavigationEntry();
        if (navEntry) fn(type, navEntry);
      } catch (error) {
        otelLogger.error(`Error in instrumentation ${type} handler:`, error);
      }
    });
  };

  private _createActiveRootSpan = (name: string, startTime: number) => {
    const rootSpan = tracer().startSpan(name, { startTime }, getTraceContext(window.traceCarrier));
    window.rootContext = trace.setSpan(context.active(), rootSpan);
    return rootSpan;
  };

  private _createEvent = (type: Event['type'], startTime: number, endTime: number): Event => ({
    type,
    startTime,
    duration: endTime - startTime,
    endTime,
  });

  private _callHook(event: Event) {
    try {
      this._getConfig().hooks?.onLoadEvent?.(event);
    } catch (hookError) {
      otelLogger.error('Error in onLoadEvent hook:', hookError);
    }
  }

  // handlers
  private handleReady(): void {
    try {
      this._wrapHandler('ready', (type, navEntry) => {
        const event = this._createEvent(type, navEntry.responseStart, navEntry.loadEventEnd);
        if (!this.rootSpan) {
          this.rootSpan = this._createActiveRootSpan('Document Load', event.startTime);
          this.rootSpan.addEvent(type, event.endTime);
        }
        this._callHook(event);
      });
    } catch (error) {
      otelLogger.error('Error in instrumentation ready handler:', error);
    }
  }

  private handleFCP(fcpEntry: PerformanceEntry): void {
    try {
      this._wrapHandler('fcp', (type, navEntry) => {
        const event = this._createEvent(type, navEntry.responseEnd, fcpEntry.startTime);
        this.rootSpan?.addEvent(type, event.endTime);
        this._callHook(event);
      });
    } catch (error) {
      otelLogger.error('Error in instrumentation fcp handler:', error);
    }
  }

  private handleLCP(lcpEntry: PerformanceEntry): void {
    try {
      this._wrapHandler('lcp', (type, navEntry) => {
        const event = this._createEvent(type, navEntry.responseEnd, lcpEntry.startTime);
        this.rootSpan?.addEvent(type, event.endTime);
        this.rootSpan?.end(event.endTime);
        this.rootSpan = undefined;
        this._callHook(event);
      });
    } catch (error) {
      otelLogger.error('Error in instrumentation fcp handler:', error);
    }
  }

  // lifecycle
  protected init(): void {}

  override enable(): void {
    try {
      // Cleanup old listeners
      this.disable();

      // If document is already complete, fire load immediately
      if (window.document.readyState === 'complete') {
        this.handleReady();
      } else {
        this.handleReady = this.handleReady.bind(this);
        window.addEventListener('load', this.handleReady);
        window.addEventListener('unload', this.disable);
      }

      // Set up FCP observer if hook exists
      this.fcpObserver = new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        const fcpEntry = entries.findLast((entry) => entry.name === 'first-contentful-paint');
        if (fcpEntry) this.handleFCP(fcpEntry);
      });
      this.fcpObserver.observe({ entryTypes: ['paint'] });

      // Set up LCP observer if hook exists
      this.lcpObserver = new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        const lcpEntry = entries[entries.length - 1];
        if (lcpEntry) this.handleLCP?.(lcpEntry);
      });
      this.lcpObserver.observe({ entryTypes: ['largest-contentful-paint'] });
    } catch (error) {
      otelLogger.error('Error in instrumentation enable:', error);
    }
  }

  override disable(): void {
    try {
      window.removeEventListener('load', this.handleReady);
      window.removeEventListener('unload', this.disable);
      if (this.fcpObserver) {
        this.fcpObserver.disconnect();
        this.fcpObserver = undefined;
      }
      if (this.lcpObserver) {
        this.lcpObserver.disconnect();
        this.lcpObserver = undefined;
      }
    } catch (error) {
      otelLogger.error('Error in instrumentation disable:', error);
    }
  }
}

export { CustomLoadInstrumentation };
