import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { PageKeyMap } from '../types/setup';
import { Event } from '../types/event';
import { isOtelLogging } from '../constants/setup';
import { createResilientStorage } from './local';

type PageKeyState = {
  loadEvents: Event[];
  fetchEvents: Event[];
  isErrored: boolean;
  isLoaded: boolean;
};

const initialPageKeyState: PageKeyState = {
  loadEvents: [],
  fetchEvents: [],
  isErrored: false,
  isLoaded: false,
};

interface EventStore {
  userId: string;
  pagePath: string;
  pageKey: string;
  pageKeyMap: PageKeyMap;
  pageKeyState: Partial<Record<string, PageKeyState>>;
  initStore: (pageKeyMap: PageKeyMap, userId: string) => void;
  resetStore: (pageKey: string, pagePath: string) => void;
  addLoadEvent: (newLoadEvent: Event) => void;
  addFetchEvent: (newFetchEvent: Event) => void;
  setIsErrored: (isErrored: boolean) => void;
  setIsLoaded: (isLoaded: boolean) => void;
}

const useEventStore = create<EventStore>()(
  devtools(
    immer((set) => ({
      // state
      userId: '',
      pageKey: 'unknown',
      pagePath: 'unknown',
      pageKeyMap: {},
      pageKeyState: {},
      // lifecycle
      initStore: (pageKeyMap: PageKeyMap, userId: string) =>
        set(
          {
            pageKey: 'unknown',
            pagePath: 'unknown',
            userId,
            pageKeyMap,
          },
          undefined,
          'initStore'
        ),
      resetStore: (pageKey: string, pagePath: string) =>
        set(
          (state) => {
            const { pageKeyState } = state;
            const newPageKeyState = {
              ...(pageKeyState[pageKey] || initialPageKeyState),
              loadEvents: [],
              fetchEvents: [],
              isErrored: false,
              isLoaded: false,
            };
            state.pagePath = pagePath;
            state.pageKey = pageKey;
            state.pageKeyState[pageKey] = newPageKeyState;
          },
          undefined,
          'resetStore'
        ),
      // events
      addLoadEvent: (newLoadEvent: Event) =>
        set(
          (state) => {
            state.pageKeyState[state.pageKey]?.loadEvents.push(newLoadEvent);
          },
          undefined,
          'addLoadEvent'
        ),
      addFetchEvent: (newFetchEvent: Event) =>
        set(
          (state) => {
            state.pageKeyState[state.pageKey]?.fetchEvents.push(newFetchEvent);
          },
          undefined,
          'addFetchEvent'
        ),
      // status
      setIsErrored: (isErrored: boolean) =>
        set(
          (state) => {
            state.pageKeyState[state.pageKey] = {
              ...(state.pageKeyState[state.pageKey] || initialPageKeyState),
              isErrored,
            };
          },
          undefined,
          'setIsErrored'
        ),
      setIsLoaded: (isLoaded: boolean) =>
        set(
          (state) => {
            state.pageKeyState[state.pageKey] = {
              ...(state.pageKeyState[state.pageKey] || initialPageKeyState),
              isLoaded,
            };
          },
          undefined,
          'setIsLoaded'
        ),
    })),
    {
      name: 'useEventStore',
      store: 'EventStore',
      enabled: isOtelLogging,
    }
  )
);

export { useEventStore };
