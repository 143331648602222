import { useIntl } from 'react-intl';
import { TStoreHours } from '@/components/store-locator/store-locator.interface';
import { DAYS_OF_WEEK_MAP } from '@/data/constants';
import { formatStoreTimeRange } from '@/utils';
import { useCommonContextProps } from '@/hooks';

export const useGetStoreHoursData = (
  storeHours: TStoreHours[],
  currentDate: Date,
  currentDayOfWeek: string,
  closed: boolean
) => {
  const { formatMessage } = useIntl();
  const { locale } = useCommonContextProps();
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const currentTime = currentHour + currentMinutes / 60;
  const currentStoreHours = storeHours.find((hours: TStoreHours) => hours.nameOfDay === currentDayOfWeek);
  const { timeRange = '' } = currentStoreHours || {};
  const { open, close, open24HrClock, close24HrClock } = formatStoreTimeRange(timeRange, formatMessage, locale);
  const nextDayOfWeek =
    currentDate.getDay() === 6
      ? formatMessage({ id: 'sunday' })
      : formatMessage({ id: DAYS_OF_WEEK_MAP[currentDate.getDay() + 1] });
  const nextDayStoreHours = storeHours.find((hours: TStoreHours) => hours.nameOfDay === nextDayOfWeek);
  const { open: nextDayOpeningTime } = formatStoreTimeRange(nextDayStoreHours?.timeRange || '', formatMessage, locale);
  const isStoreClosed = closed || currentTime > close24HrClock || currentTime < open24HrClock;
  const isStoreClosedTomorrow = nextDayStoreHours?.timeRange === formatMessage({ id: 'closed' });

  const findNextOpenDay = () => {
    let day = 0;
    for (var i = 0; i < 7; i++) {
      day = (i + currentDate.getDay() + 1) % 7;
      const hours = storeHours.find(
        (hours: TStoreHours) => hours.nameOfDay === formatMessage({ id: DAYS_OF_WEEK_MAP[day] })
      );
      if (hours?.timeRange !== formatMessage({ id: 'closed' })) {
        break;
      }
    }
    return DAYS_OF_WEEK_MAP[day];
  };

  const storeHoursLabel = () => {
    let labelText;

    if (timeRange === formatMessage({ id: '24hours' })) {
      // OPEN 24 hours
      labelText = timeRange;
    } else if ((closed || currentTime > close24HrClock) && isStoreClosedTomorrow) {
      // CLOSED and store does not open tomorrow
      const nextOpenDay = formatMessage({ id: findNextOpenDay() });
      labelText = formatMessage({ id: 'untilTime' }, { time: nextOpenDay });
    } else if ((closed || currentTime > close24HrClock) && !isStoreClosedTomorrow) {
      // CLOSED and store opens tomorrow
      labelText = formatMessage({ id: 'closedUntilTomorrow' }, { time: nextDayOpeningTime });
    } else if (currentTime < open24HrClock) {
      // CLOSED and store opens today
      labelText = formatMessage({ id: 'closedUntilToday' }, { time: open });
    } else if (close24HrClock == 24) {
      // OPEN until midnight
      labelText = formatMessage({ id: 'untilMidnight' });
    } else {
      // OPEN until a specific time
      labelText = formatMessage({ id: 'untilTime' }, { time: close });
    }

    return labelText;
  };

  return { storeHoursLabel, isStoreClosed };
};

export default useGetStoreHoursData;
