import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  ParentBasedSampler,
  TraceIdRatioBasedSampler,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { detectResourcesSync, envDetector, hostDetector, osDetector, Resource } from '@opentelemetry/resources';
import { ConsoleMetricExporter, MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics';
import { B3InjectEncoding, B3Propagator } from '@opentelemetry/propagator-b3';
import { diag, DiagConsoleLogger, DiagLogLevel, metrics } from '@opentelemetry/api';
import { v4 as uuid } from 'uuid';
import { escapeRegExp } from 'lodash';
import {
  appName,
  isNodeRuntime,
  isOtelEnabled,
  isOtelLogging,
  metricsIntervalMs,
  metricsUrl,
  tracesSampleRatio,
  tracesUrl,
  isJestRuntime,
  headers,
} from '../constants/setup';
import { handleEvent, handleInit, handleNavigate } from '../services/event';
import { CustomLoadInstrumentation } from '../instrumentation/load';
import { OtelRemoteConfigs, PageKeyMap } from '../types/setup';
import { setupCSRTelemetryFlush } from '../processors/flush';
import '../types/global';
import { CustomFetchInstrumentation } from '../instrumentation/fetch';
import { GatedMetricExporter } from '@/telemetry-sdk/processors/gated-metric-exporter';
import { convertKamFlagsToOtelRemoteConfigs, getOtelConfigFromNextData } from 'Utils/remote-config-utils';
import { isPharmaprixBanner } from '@/utils';
import { getSnowplowUserData } from 'Utils/analytics/analytics-utils';
import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';
import { getResourceAttributes } from '@/telemetry-sdk';
import { AggregationTemporalityPreference } from '@opentelemetry/exporter-metrics-otlp-http';

const otelLogger = diag.createComponentLogger({
  namespace: '✨ @telemetry-sdk/setup/csr',
});
if (!isNodeRuntime && isOtelLogging) {
  diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.INFO);
}

let isOtelInitialized = false;
let gatedMetricExporter: GatedMetricExporter | null = null;

type OtelRegisterProps = {
  bffUrl: string | undefined;
  pageKeyMap: PageKeyMap;
  userId: string | undefined;
};

export async function refreshOtelConfigs(kamFlags: Record<string, IKamFlag> | null): Promise<void> {
  if (isNodeRuntime) {
    return;
  }

  try {
    const snowplowData = getSnowplowUserData();
    const userId = snowplowData.domainUserId;

    if (!userId) {
      return;
    }

    if (kamFlags) {
      const remoteConfigs = convertKamFlagsToOtelRemoteConfigs(kamFlags);

      if (remoteConfigs.status) {
        gatedMetricExporter?.enable();
      } else {
        gatedMetricExporter?.disable();
      }
    }
  } catch (error) {
    otelLogger.error('Error refreshing otel configs:', error);
  }
}

const otelRegister = (props: OtelRegisterProps) => {
  const { bffUrl, pageKeyMap, userId = uuid() } = props;

  // Runtime Check
  if (isJestRuntime || isNodeRuntime || isOtelInitialized || !bffUrl) {
    if (!isNodeRuntime)
      otelLogger.error(`Otel disabled for '${appName}' - CSR`, {
        isJestRuntime,
        isNodeRuntime,
        isOtelInitialized,
        bffUrl,
      });
    return;
  }

  isOtelInitialized = true;
  const isBannerPharma = isPharmaprixBanner();

  // Resource
  const resource = new Resource(getResourceAttributes(isBannerPharma)).merge(
    detectResourcesSync({
      detectors: [envDetector, hostDetector, osDetector],
    })
  );

  // Trace Provider
  const tracerProvider = new WebTracerProvider({
    resource,
    sampler: new ParentBasedSampler({
      root: new TraceIdRatioBasedSampler(tracesSampleRatio),
    }),
    spanProcessors: [
      ...(isOtelEnabled
        ? [
            new BatchSpanProcessor(
              new OTLPTraceExporter({
                url: tracesUrl,
                headers: headers,
              })
            ),
          ]
        : []),
      ...(isOtelEnabled && isOtelLogging ? [new BatchSpanProcessor(new ConsoleSpanExporter())] : []),
    ],
  });

  let otelConfig: OtelRemoteConfigs = getOtelConfigFromNextData();
  gatedMetricExporter = new GatedMetricExporter(
    metricsUrl,
    otelConfig.status,
    headers,
    AggregationTemporalityPreference.DELTA
  );

  const meterProvider = new MeterProvider({
    resource,
    readers: [
      ...(isOtelEnabled
        ? [
            new PeriodicExportingMetricReader({
              exporter: gatedMetricExporter,
              exportIntervalMillis: metricsIntervalMs,
            }),
          ]
        : []),
      ...(isOtelLogging && isOtelLogging
        ? [
            new PeriodicExportingMetricReader({
              exporter: new ConsoleMetricExporter(),
              exportIntervalMillis: 1000,
            }),
          ]
        : []),
    ],
  });

  // Propagator
  const propagator = new CompositePropagator({
    propagators: [
      new W3CTraceContextPropagator(),
      new W3CBaggagePropagator(),
      new B3Propagator({
        injectEncoding: B3InjectEncoding.SINGLE_HEADER,
      }),
    ],
  });

  // Register Instrumentation
  registerInstrumentations({
    tracerProvider,
    meterProvider,
    instrumentations: [
      new CustomLoadInstrumentation({
        hooks: {
          onLoadEvent: handleEvent,
        },
      }),
      new CustomFetchInstrumentation({
        options: {
          clearTimingResources: true,
          ignoreNetworkEvents: false,
          measureRequestSize: false,
        },
        urls: {
          propagatedUrls: [new RegExp(`^${escapeRegExp(bffUrl)}`)],
          ignoredUrls: [tracesUrl, metricsUrl],
        },
        hooks: {
          onFetchEvent: handleEvent,
        },
      }),
    ],
  });

  // Unload Handler
  setupCSRTelemetryFlush({ tracerProvider, meterProvider });

  // Register Providers
  tracerProvider.register({
    contextManager: new ZoneContextManager(),
    propagator,
  });
  metrics.setGlobalMeterProvider(meterProvider);

  // Complete
  otelLogger.info(`Otel enabled for '${appName}' - CSR`, { exporting: isOtelEnabled });

  // Init Store
  handleInit(pageKeyMap, userId);
  handleNavigate(window.location.pathname);
};

export { otelRegister };
