import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';

import { handleNavigate } from '../services/event';

interface OtelNavigateProps {
  isEnabled?: boolean;
}

const useOtelNavigate = (props?: OtelNavigateProps) => {
  const { isEnabled = true } = props ?? {};
  const router = useRouter();

  const handleRouteChange = useCallback(
    (url: string) => {
      if (isEnabled) handleNavigate(url);
    },
    [isEnabled]
  );

  useEffect(() => {
    handleRouteChange(router.asPath);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, handleRouteChange]);

  return {
    currentPath: router.asPath,
    isEnabled: isEnabled,
  };
};

export { useOtelNavigate };
