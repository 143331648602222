import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';
import { getKameleoonClient, getKameleoonFlags } from 'Utils/kameleoon/kameloon-sdk';
import { OtelRemoteConfigs } from '@/telemetry-sdk';

export const getOtelConfigFromNextData = (): OtelRemoteConfigs => {
  const nextData = window.__NEXT_DATA__;
  if (!nextData || !nextData.props || !nextData.props.pageProps || !nextData.props.pageProps.kamFlags) {
    return { status: false };
  }
  const kamFlags = nextData.props.pageProps.kamFlags;
  return convertKamFlagsToOtelRemoteConfigs(kamFlags);
};

export const convertKamFlagsToOtelRemoteConfigs = (
  activeKamFeatureFlags: Record<string, IKamFlag> | null
): OtelRemoteConfigs => {
  if (!activeKamFeatureFlags) {
    return { status: false };
  }

  const sdmFeatureKeyName = 'sdm-web-otel';
  const variableKey = 'sdm_otel';
  const bffVariableKey = 'bff.exp.sdm_otel';

  const result = Object.entries(activeKamFeatureFlags)
    .filter(([featureKey, exp]: [string, any]) => featureKey === sdmFeatureKeyName)
    .map(([key, exp]: [string, any]) => {
      return exp.variables;
    })
    .flat()
    .map((variable: any) => variable.value)
    .flat()
    .filter((val) => val && val.featureFlag && val.featureFlag === variableKey)
    .map(({ featureFlag, status }) => {
      const boolStatus = status === true || status === 'true';
      return {
        name: `bff.exp.${featureFlag}`,
        status: boolStatus,
      };
    })
    .find((flag) => {
      return flag && flag.name === bffVariableKey;
    });

  if (!result) {
    return { status: false };
  }

  return result;
};

export const getRemoteConfigs = async (userId: string): Promise<OtelRemoteConfigs> => {
  try {
    const kamClient = await getKameleoonClient();
    let activeKamFeatureFlags: Record<string, IKamFlag> | null = null;
    if (kamClient) {
      try {
        activeKamFeatureFlags = await getKameleoonFlags(userId, userId);
      } catch (kamError) {
        console.error('Error fetching Kameleoon flags:', kamError);
        return { status: false };
      }
    }

    if (activeKamFeatureFlags == null) {
      return { status: false };
    }

    const result = convertKamFlagsToOtelRemoteConfigs(activeKamFeatureFlags);
    return result || { status: false, alwaysOnTracingUserIds: [] };
  } catch (error) {
    console.error('Error fetching remote configs:', error);
    return { status: false };
  }
};
