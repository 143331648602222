import { PageKeyMap } from '../types/setup';

/**
 * Extracts pathname from a URL string without using URL object
 * @param urlString - Full URL string or pathname
 * @returns pathname string
 */
const getPathName = (urlString: string): string => {
  // Handle relative paths that start with /
  if (urlString.startsWith('/')) {
    return urlString.split('?')[0].split('#')[0];
  }

  // For full URLs, find the first '/' after the domain
  const afterProtocol = urlString.split('//').pop() || '';
  const afterDomain = afterProtocol.indexOf('/');

  if (afterDomain === -1) {
    return '/';
  }

  const fullPath = afterProtocol.slice(afterDomain);
  // Remove query parameters and hash
  return fullPath.split('?')[0].split('#')[0];
};

const getPathKey = (pathName: string, pageKeyMap: PageKeyMap) => {
  for (const pageKey in pageKeyMap) {
    const servicePath = pageKey;
    if (pageKeyMap[servicePath]?.toLowerCase() === pathName.toLowerCase()) {
      return servicePath;
    }
  }
  return 'unknown';
};

export { getPathName, getPathKey };
