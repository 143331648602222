import { context, propagation, trace, Span } from '@opentelemetry/api';
import { appName, clientType, criticalPathsSampled } from '../constants/setup';
import { TraceCarrier } from '../types/setup';

const tracer = () => trace.getTracer(`${appName}-tracer`);

// Set trace carrier to current context
const getTraceContext = (traceCarrier?: TraceCarrier) => {
  if (!traceCarrier) return;
  return propagation.extract(context.active(), traceCarrier);
};

// Get trace carrier from current context
const getTraceCarrier = (span?: Span) => {
  const carrier: TraceCarrier = {};
  const spanContext = span ? trace.setSpan(context.active(), span) : context.active();
  carrier.tracestate = addTraceState(carrier.tracestate, String(criticalPathsSampled));
  propagation.inject(spanContext, carrier);
  return carrier;
};

function addTraceState (traceState?: string, value?: string) {
  if (!value) return traceState;
  return !traceState ? value : `${traceState},${value}`;
}


export { tracer, getTraceContext, getTraceCarrier };
