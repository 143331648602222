import { QueryClient } from '@tanstack/react-query';
import { fetchFireworkProducts } from '@/api/use-get-firework-products';
import { FireworkProduct, FireworkStore, FireworkInputProduct, SDMProduct } from '@/store/firework/firework.types';

const queryClient = new QueryClient();

/**
 * Helper method to convert SDM product data to Firework's expected product format
 * @param sdmProduct - The SDM product data from our backend
 * @param fwProduct - The Firework product input data containing product_units
 * @returns Firework-formatted product or null if conversion fails
 */
const createFWProduct = (sdmProduct: SDMProduct, fwProduct: FireworkInputProduct) => {
  // Firework supports multiple product units, but we only use the first one
  // as recommended by Firework for basic integration
  const productUnit = fwProduct.product_units?.[0];
  if (!productUnit) return null;

  return window?._fwn?.shopping.productFactory((product) => {
    // 1. Set base product details from SDM data
    product
      .extId(productUnit.product_ext_id)
      .name(sdmProduct.name)
      .description(sdmProduct.description)
      .currency(sdmProduct.price.currencyIso);

    // 2. Configure product variant with pricing and availability
    product.variant((variant: any) => {
      // Use special price if available, otherwise use regular price
      const currentPrice = Number(sdmProduct.specialPrice?.value || sdmProduct.price.value);
      // Original price comes from Firework data
      const originalPrice = Number(
        fwProduct.product_units?.find((unit) => unit.unit_ext_id === productUnit.product_ext_id)?.unit_original_price
      );

      variant
        .extId(productUnit.unit_ext_id)
        .name(sdmProduct.name)
        .price(currentPrice)
        .originalPrice(originalPrice)
        .isAvailable(sdmProduct.isAvailable);

      // Add product image
      variant.image((imageBuilder: any) => {
        imageBuilder.extId(productUnit.unit_ext_id).url(sdmProduct.images.thumbnail).extId(0).position(0);
      });

      return variant;
    });

    return product;
  });
};

/**
 * Initializes Firework product hydration system. This handles two types of products:
 * 1. Regular products with SDM_ prefixed IDs in their product_units
 * 2. Legacy products that use SDM_ hashtags to reference products
 *
 * @param store - Store instance to cache hydrated products
 * @returns Cleanup function to remove event listeners
 */
export const initializeFireworkHydration = (store: FireworkStore): (() => void) => {
  let onProductsLoadedCallback:
    | ((props: { products: FireworkInputProduct[]; video?: { hashtags?: string[] } }) => Promise<any>)
    | null = null;

  if (window._fwn?.shopping) {
    onProductsLoadedCallback = async ({ products, video = { hashtags: [] } }) => {
      try {
        // 1. Get SDM product IDs from hashtags, normalize to uppercase immediately
        const hashtags =
          video?.hashtags?.filter((tag) => tag.toUpperCase().startsWith('SDM_')).map((tag) => tag.toUpperCase()) || [];

        // 2. Map legacy products to SDM hashtags in order
        const legacyProductMapping = new Map();
        products.forEach((fwProduct, index) => {
          const productUnit = fwProduct.product_units?.[0];
          const productId = productUnit?.product_ext_id?.toUpperCase();
          if (productUnit && !productId?.startsWith('SDM_')) {
            legacyProductMapping.set(index, hashtags[legacyProductMapping.size]);
          }
        });

        // 3. Collect all unique product IDs, normalized to uppercase
        const productIds = [
          ...new Set(
            [
              ...products.map((fwProduct) => fwProduct.product_units?.[0]?.product_ext_id?.toUpperCase()),
              ...hashtags,
            ].filter((id) => id?.startsWith('SDM_'))
          ),
        ];

        if (!productIds.length) return products;

        // 4. Fetch only products we don't have cached
        const missingProductIds = productIds.filter((id) => !store.hydratedProducts[id as string]);
        let productDetails = { products: {} };

        if (missingProductIds.length) {
          productDetails = await queryClient.fetchQuery({
            queryKey: ['products', missingProductIds],
            queryFn: () => fetchFireworkProducts(missingProductIds.filter(Boolean) as string[]),
          });

          // Cache the fetched products
          if (productDetails?.products) {
            Object.entries(productDetails.products).forEach(([id, data]) => {
              store.setHydratedProduct(id, data as FireworkProduct);
            });
          }
        }

        // 5. Map Firework products to hydrated versions
        const hydratedProducts = products
          .map((fwProduct, index) => {
            const productUnit = fwProduct.product_units?.[0];
            if (!productUnit) return null;

            const productId = productUnit.product_ext_id?.toUpperCase();

            // Try getting product by ext_id first
            let sdmProduct =
              store.hydratedProducts[productId] || (productDetails?.products as Record<string, any>)[productId];

            // For legacy products, get the mapped SDM product from hashtags
            if (!sdmProduct && !productId?.startsWith('SDM_')) {
              const mappedHashtag = legacyProductMapping.get(index);
              if (mappedHashtag) {
                sdmProduct = (productDetails?.products as Record<string, any>)[mappedHashtag];
              }
            }

            if (!sdmProduct) return null;
            return createFWProduct(sdmProduct, fwProduct);
          })
          .filter(Boolean);

        return hydratedProducts;
      } catch (error) {
        console.error('Failed to hydrate products:', error);
        return products;
      }
    };
  }

  // Set up callback and return cleanup function
  window._fwn?.shopping.onProductsLoaded(onProductsLoadedCallback || (() => {}));
  return () => {
    window._fwn?.shopping.onProductsLoaded(() => {});
  };
};
