import React from 'react';
import { MeterCarrier, TraceCarrier } from '../types/setup';

type OtelCarrierProps = {
  traceCarrier?: TraceCarrier;
  meterCarrier?: MeterCarrier;
};

const OtelCarrier: React.FC<OtelCarrierProps> = (props) => {
  const { traceCarrier, meterCarrier } = props;

  return (
    <>
      {/*set window from SSR - must use dangerouslySetInnerHTML to be available on document load*/}
      {traceCarrier && (
        <script dangerouslySetInnerHTML={{ __html: `window.traceCarrier = ${JSON.stringify(traceCarrier)};` }} />
      )}
      {meterCarrier && (
        <script dangerouslySetInnerHTML={{ __html: `window.meterCarrier = ${JSON.stringify(meterCarrier)};` }} />
      )}

      {/*set meta tags from SSR*/}
      {traceCarrier &&
        Object.entries(traceCarrier).map(([key, value]) =>
          value ? <meta key={key} name={key} content={value} /> : null
        )}
    </>
  );
};

export { OtelCarrier };
