import { debounce } from 'lodash';

import { useEventStore } from '../store/event';
import { Event } from '../types/event';
import { PageKeyMap } from '../types/setup';
import {
  getFullDuration,
  getOpDurationHistogram,
  getOpErrorsCounter,
  getOpNetworkDurationHistogram,
  getOpTotalCounter,
} from '../utils/metrics';
import { getPathKey, getPathName } from '../utils/url';
import { getExtendedAttributes } from '../utils/setup';
import { diag } from '@opentelemetry/api';

const otelLogger = diag.createComponentLogger({
  namespace: '✨ @telemetry-sdk/services/event',
});

const one = 1;
// services
const handleInit = (pageKeyMap: PageKeyMap, userId: string) => {
  otelLogger.info('Handle Init:', pageKeyMap);
  const { initStore } = useEventStore.getState();
  initStore(pageKeyMap, userId);
};

const handleNavigate = (pageUrl: string) => {
  const { userId, pagePath, pageKeyMap, resetStore } = useEventStore.getState();

  // get new page path and key
  const newPagePath = getPathName(pageUrl);
  const newPageKey = getPathKey(newPagePath, pageKeyMap);

  // return early if pagePath is the same
  if (pagePath === newPagePath) return;
  otelLogger.info('Handle Navigate:', pageUrl);

  // reset store if pagePath is different
  resetStore(newPageKey, newPagePath);
  // call a metric with pageKey + page init
  const pageOpTotalCounter = getOpTotalCounter(newPageKey);
  pageOpTotalCounter.add(one, getExtendedAttributes(newPageKey, userId));
};

const handleEvent = (event: Event) => {
  otelLogger.info('Handle Event:', event.type, event);
  const { userId, pageKey, addFetchEvent, addLoadEvent, setIsErrored } = useEventStore.getState();

  // add event to store
  if (event.type === 'fetch') {
    addFetchEvent(event);
  } else {
    addLoadEvent(event);
  }
  handleLoadedDebounced();

  // get latest fetch events
  const { fetchEvents = [], isErrored = false } = useEventStore.getState().pageKeyState[pageKey] ?? {};

  // return early if already metered or no fetch errors
  const hasFetchError = fetchEvents.some((fetch) => fetch.isError);
  if (isErrored || !hasFetchError) return;

  setIsErrored(true);

  // call a metric with pageKey + page error
  const pageOpErrorsCounter = getOpErrorsCounter(pageKey);
  pageOpErrorsCounter.add(one, getExtendedAttributes(pageKey, userId));
};

const handleLoaded = () => {
  const { userId, pageKey, pageKeyState } = useEventStore.getState();
  const { loadEvents = [], fetchEvents = [], isLoaded = false } = pageKeyState[pageKey] ?? {};

  // return early if already loaded
  if (isLoaded) return;
  otelLogger.info('Handle Loaded');

  // calc durations
  const backendFetchDurationMs = window.meterCarrier?.restDuration || 0;
  const frontendFetchDurationMs = getFullDuration(fetchEvents);
  const loadDurationMs = getFullDuration(loadEvents);
  const fetchDurationMs = backendFetchDurationMs + frontendFetchDurationMs;

  // set isLoaded to true
  useEventStore.getState().setIsLoaded(true);

  // call a metric with pageKey + page load duration
  const pageOpDurationHistogram = getOpDurationHistogram(pageKey);
  pageOpDurationHistogram.record(loadDurationMs / 1000, getExtendedAttributes(pageKey, userId));

  // call a metric with pageKey + combined rest duration
  const pageOpNetworkDurationHistogram = getOpNetworkDurationHistogram(pageKey);
  pageOpNetworkDurationHistogram.record(fetchDurationMs / 1000, getExtendedAttributes(pageKey, userId));
};
const handleLoadedDebounced = debounce(handleLoaded, 5000, { leading: false, trailing: true });

export { handleInit, handleNavigate, handleEvent };
