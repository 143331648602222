import React, { createContext, useContext, useState } from 'react';

interface FireworkStore {
  hydratedProducts: Record<string, any>;
  setHydratedProduct: (productId: string, data: any) => void;
  getHydratedProduct: (productId: string) => any;
}

const FireworkContext = createContext<FireworkStore | null>(null);

export const FireworkProvider = ({ children }: { children: React.ReactNode }) => {
  const [hydratedProducts, setHydratedProducts] = useState<Record<string, any>>({});

  const setHydratedProduct = (productId: string, data: any) =>
    setHydratedProducts((prev) => ({ ...prev, [productId]: data }));

  const getHydratedProduct = (productId: string) => hydratedProducts[productId];

  return (
    <FireworkContext.Provider value={{ hydratedProducts, setHydratedProduct, getHydratedProduct }}>
      {children}
    </FireworkContext.Provider>
  );
};

export const useFireworkStore = () => {
  const context = useContext(FireworkContext);
  if (!context) throw new Error('useFireworkStore must be used within FireworkProvider');
  return context;
};
