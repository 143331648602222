import {
  PushMetricExporter,
  ResourceMetrics,
  AggregationTemporality,
  InstrumentType,
  Aggregation,
} from '@opentelemetry/sdk-metrics';
import {
  AggregationTemporalityPreference,
  OTLPMetricExporter,
  OTLPMetricExporterOptions,
} from '@opentelemetry/exporter-metrics-otlp-http';
import { ExportResult, ExportResultCode } from '@opentelemetry/core';

export class GatedMetricExporter implements PushMetricExporter {
  private exporter: OTLPMetricExporter | null;
  private enabled: boolean;
  private metricsUrl: string | undefined;
  private headers: any | undefined;
  private temporalityPreference: AggregationTemporalityPreference | undefined;

  constructor(
    metricsUrl: string,
    isEnabled: boolean,
    headers: any,
    temporalityPreference: AggregationTemporalityPreference | undefined
  ) {
    this.enabled = isEnabled;
    this.metricsUrl = metricsUrl;
    this.headers = headers;
    this.temporalityPreference = temporalityPreference;
    this.exporter = null;
    if (isEnabled) {
      this.exporter = this.createMetricExporter();
    }
  }

  // ✅ Dynamically enable or disable metric exporting
  enable() {
    if (this.enabled) {
      return;
    }
    this.exporter = this.createMetricExporter();
    this.enabled = true;
  }

  private createMetricExporter() {
    const exporterOptions: OTLPMetricExporterOptions = {
      url: this.metricsUrl,
      headers: this.headers as Record<string, string> | undefined,
    };
    if (this.temporalityPreference !== undefined) {
      exporterOptions.temporalityPreference = this.temporalityPreference;
    }

    return new OTLPMetricExporter(exporterOptions);
  }

  disable() {
    this.exporter = null;
    this.enabled = false;
  }

  export(metrics: ResourceMetrics, resultCallback: (result: ExportResult) => void): void {
    if (this.enabled && this.exporter) {
      this.exporter.export(metrics, resultCallback);
    } else {
      // Drop metrics if not enabled
      resultCallback({ code: ExportResultCode.SUCCESS });
    }
  }

  forceFlush(): Promise<void> {
    return this.exporter ? this.exporter.forceFlush() : Promise.resolve();
  }

  shutdown(): Promise<void> {
    return this.exporter ? this.exporter.shutdown() : Promise.resolve();
  }

  selectAggregationTemporality(instrumentType: InstrumentType): AggregationTemporality {
    // If exporter not configured return same default as underlying OTLPMetricExporter
    return this.exporter
      ? this.exporter.selectAggregationTemporality(instrumentType)
      : AggregationTemporality.CUMULATIVE;
  }

  selectAggregation(instrumentType: InstrumentType): Aggregation {
    // If exporter not configured return same default as underlying OTLPMetricExporter
    return this.exporter ? this.exporter.selectAggregation(instrumentType) : Aggregation.Default();
  }
}
