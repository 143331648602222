import { KameleoonVisitorCodeManager } from '@kameleoon/nextjs-visitor-code-manager';
import { KameleoonEventSource } from '@kameleoon/nodejs-event-source';
import {
  CredentialsType,
  CustomData,
  Environment,
  KameleoonClient,
  KameleoonError,
  SDKConfigurationType,
} from '@kameleoon/nodejs-sdk';
import { KameleoonRequester } from '@kameleoon/nodejs-requester';
import { KAMELEOON, KAMELEOON_CREDENTIALS, KamErrors, KameleoonDomains } from './constants';
import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';
let client: KameleoonClient | null;

/**
 * A custom errorHandler to print error messages
 * @param {string} type - Provide an identifier to know which function is receiving an error
 * @param {unknown} error - Caught error from try/catch, this will be a JavaScript Error or the custom KameleoonError from SDK
 * @returns undefined
 */
const errorHandler = (type: string, error: unknown): void => {
  if (error instanceof KameleoonError) {
    console.log(`${error.type}: ${error.message}`);
    return;
  } else {
    console.log(`KamSdk ${type}: ${error}`);
  }
};

export const getKameleoonClient = async () => {
  if (!client || !client?.isInitialized()) {
    await initializeKameleeonSDK();
  }

  return client;
};

// initializing the global client for application (we are not connecting client data at this point)
const initializeKameleeonSDK = async () => {
  // -- Mandatory credentials
  const credentials: CredentialsType = {
    clientId: KAMELEOON_CREDENTIALS.CLIENT_ID,
    clientSecret: KAMELEOON_CREDENTIALS.CLIENT_SECRET,
  };

  //Get Kam Domain
  const getDomain = (env: Environment): KameleoonDomains => {
    switch (env) {
      case Environment.Development:
        return KameleoonDomains.Development;
      case Environment.Staging:
        return KameleoonDomains.Staging;
      case Environment.Production:
        return KameleoonDomains.Production;
      default:
        return KameleoonDomains.Development;
    }
  };

  const env = process.env.NEXT_PUBLIC_KAMELEOON_ENV as Environment;

  // -- Optional configuration
  const configuration: Partial<SDKConfigurationType> = {
    updateInterval: 20,
    environment: env,
    cookieDomain: getDomain(env),
    targetingDataCleanupInterval: 5,
  };

  try {
    client = new KameleoonClient({
      siteCode: KAMELEOON.SITE_CODE,
      credentials,
      configuration,
      externals: {
        visitorCodeManager: new KameleoonVisitorCodeManager(),
        eventSource: new KameleoonEventSource(),
        requester: new KameleoonRequester(),
      },
    });

    const initializeKamClient = async () => {
      await client?.initialize();
    };

    await initializeKamClient();
  } catch (error) {
    errorHandler(KamErrors.Init, error);
    client = null;
  }
};

// this is where we are using client-specific data
export const setWarehouseData = async (visitorCode: string, warehouseKey: string) => {
  try {
    const warehouseData = await client?.getVisitorWarehouseAudience({
      visitorCode: visitorCode, // spid (snowplow id)
      warehouseKey: warehouseKey, // pcid or spid if user is guest/anonymous
      customDataIndex: 5, //pcid /spid warehousekey index value of 5 corresponds to Kam Dashboard setup
    });

    if (!warehouseData) {
      client?.addData(visitorCode, new CustomData(5, ''));
    } else {
      client?.addData(visitorCode, warehouseData);
    }
  } catch (error) {
    errorHandler(KamErrors.WarehouseKey, error);
  }
};

export const getKameleoonFlags = async (
  visitorCode: string,
  warehouseKey: string
): Promise<Record<string, IKamFlag>> => {
  if (!visitorCode || !client || !client?.isInitialized()) return {};

  try {
    await setWarehouseData(visitorCode, warehouseKey);
    const activeFlags = client?.getVariations({ visitorCode, onlyActive: true });
    return Array.from(activeFlags.entries()).reduce((featureFlags, lineItem) => {
      const [key, value] = lineItem;
      const variationDetails = client?.getVariation({ visitorCode, featureKey: key });

      return {
        ...featureFlags,
        [key]: {
          ...value,
          variation: variationDetails?.key || '',
          variables: Array.from(variationDetails?.variables?.values() || []),
        },
      };
    }, {});
  } catch (error) {
    errorHandler(KamErrors.WarehouseKey, error);
    return {};
  }
};
