export const ASSETS_DOMAIN = 'assets.beauty.shoppersdrugmart.ca' as const;
export const assetsPath = `https://${ASSETS_DOMAIN}/bb-prod-marketing-image` as const;
export const chevronIcon = `${assetsPath}/site-icons/chevron(black).svg` as const;

// 2020 new site Icons
export const chevronDown = `${assetsPath}/site-icons/2020-site-icons/chevron-down.svg` as const;
export const chevronUp = `${assetsPath}/site-icons/2020-site-icons/chevron-up.svg` as const;
export const chevronRightIcon = `${assetsPath}/site-icons/2020-site-icons/chevron-right.svg` as const;
export const chevronLeftIcon = `${assetsPath}/site-icons/2020-site-icons/chevron-left.svg` as const;
export const chevronLeftIconGrey = `${assetsPath}/site-icons/2020-site-icons/chevron-left-grey.svg` as const;
export const chevronRightIconGrey = `${assetsPath}/site-icons/2020-site-icons/chevron-right-grey.svg` as const;
export const chevronRight24Icon = `${assetsPath}/site-icons/2020-site-icons/chevron-right-24px.svg` as const;
export const chevronRight24pxIcon = `${assetsPath}/site-icons/2020-site-icons/chevron_right_24px.svg` as const;
export const chevronLeftWhiteIcon = `${assetsPath}/site-icons/2020-site-icons/chevron-left-white.svg` as const;
export const chevronDownUiGrey75 = `${assetsPath}/site-icons/2020-site-icons/chevDown-ui-grey-75.svg` as const;
export const chevronDownUiGrey100 = `${assetsPath}/site-icons/2020-site-icons/chevDown-ui-grey-100.svg` as const;
export const chevronDownUiBlack100 = `${assetsPath}/site-icons/2020-site-icons/chevDown-ui-black-100.svg` as const;
export const closeIcon = `${assetsPath}/site-icons/2020-site-icons/close.svg` as const;
export const closeIconWhite = `${assetsPath}/site-icons/2020-site-icons/closeWhite.svg` as const;
export const favouriteHeartIcon = `${assetsPath}/site-icons/2020-site-icons/favourite-heart-outline.svg` as const;
export const favouriteHeartIconFilled = `${assetsPath}/site-icons/2020-site-icons/favourite-heart-filled.svg` as const;
export const favouriteHeartIconFilledDisabled =
  `${assetsPath}/site-icons/2020-site-icons/favourite-heart-disabled.svg` as const;
export const ratingStarIcon = `${assetsPath}/site-icons/2020-site-icons/black-star.svg` as const;
export const ratingStarHalfIcon = `${assetsPath}/site-icons/2020-site-icons/rating-star-half.svg` as const;
export const ratingStarGreyIcon = `${assetsPath}/site-icons/2020-site-icons/grey-star.svg` as const;
export const ratingStarGreyHoverIcon = `${assetsPath}/site-icons/2020-site-icons/grey-star-hover.svg` as const;
export const alertOctagonIcon = `${assetsPath}/site-icons/2020-site-icons/alert-octagon.svg` as const;
export const skeletonChevronRight = `${assetsPath}/site-icons/2020-site-icons/skeleton-chevron-right.svg` as const;
export const skeletonChevronUp = `${assetsPath}/site-icons/2020-site-icons/skeleton-chevron-up.svg` as const;
export const skeletonChevronLeft = `${assetsPath}/site-icons/2020-site-icons/skeleton-chevron-left.svg` as const;
export const playButton = `${assetsPath}/site-icons/2020-site-icons/playButton.png` as const;
export const pauseButton = `${assetsPath}/site-icons/2020-site-icons/pauseButton.png` as const;
export const fourStarUp = `${assetsPath}/site-icons/2020-site-icons/4stars.svg` as const;
export const threeStarUp = `${assetsPath}/site-icons/2020-site-icons/3stars.svg` as const;
export const twoStarUp = `${assetsPath}/site-icons/2020-site-icons/2stars.svg` as const;
export const oneStarUp = `${assetsPath}/site-icons/2020-site-icons/1stars.svg` as const;
export const fiveStar = `${assetsPath}/site-icons/2020-site-icons/5star.svg` as const;
export const addIcon = `${assetsPath}/site-icons/2020-site-icons/add-24px.svg` as const;
export const removeIcon = `${assetsPath}/site-icons/2020-site-icons/remove-24px.svg` as const;
export const successCircleIcon = `${assetsPath}/site-icons/2020-site-icons/success-circle.svg` as const;
export const toggleTipIcon = `${assetsPath}/site-icons/2020-site-icons/toggle-tip.svg` as const;
export const warningCircleIcon = `${assetsPath}/site-icons/2020-site-icons/warning-circle.svg` as const;
export const removeIconGrey = `${assetsPath}/site-icons/2020-site-icons/remove-icon-grey.svg` as const;
export const removeIconBlack = `${assetsPath}/site-icons/2020-site-icons/remove-icon-black.svg` as const;
export const addIconGrey = `${assetsPath}/site-icons/2020-site-icons/add-icon-grey.svg` as const;
export const addIconBlack = `${assetsPath}/site-icons/2020-site-icons/add-icon-black.svg` as const;
export const pcidLogo = `${assetsPath}/site-icons/2020-site-icons/pcid-logo.svg` as const;
export const alertCircle = `${assetsPath}/site-icons/2020-site-icons/alert-circle.svg` as const;
export const PHARMA_LOGO = `${assetsPath}/site-icons/2020-site-icons/pharma_logo.svg` as const;
export const SDM_LOGO = `${assetsPath}/site-icons/2020-site-icons/sdm_logo.svg` as const;

// site media assets
export const pcidLogoLarge = `${assetsPath}/site-media/pcid.svg` as const;
export const pcOptimumLogo = `${assetsPath}/site-media/pco_logo.svg` as const;
export const pcOptimumInsidersLogoEN = `${assetsPath}/site-media/pc_optimum_insiders_en.svg` as const;
export const pcOptimumInsidersLogoFR = `${assetsPath}/site-media/pc_optimum_insiders_fr.svg` as const;
export const giftWrapImageUrl = `${assetsPath}/site-media/gift-wrap-packaging.jpg` as const;
export const pcoChevron = `${assetsPath}/site-media/new-checkout/pco-chevron.svg` as const;
export const pcoRing = `${assetsPath}/site-media/new-checkout/pco-ring.svg` as const;
export const favicon = `${assetsPath}/site-media/favicon-mb.ico` as const;

//modiface
export const MODIFACE_DEFAULT_SDK_VERSION = 'v8.0.1' as const;
const MODIFACE_SDK_VERSION = process.env.MODIFACE_SDK_VERSION ?? MODIFACE_DEFAULT_SDK_VERSION;
export const MODIFACE_PATH = `/bb-prod-marketing-image/modiface/${MODIFACE_SDK_VERSION}/` as const;
export const MODIFACE_MASK_IMAGES = `${assetsPath}/modiface/${MODIFACE_SDK_VERSION}/mask_images/` as const;
export const MODFIACE_ASSETS = `${assetsPath}/modiface/${MODIFACE_SDK_VERSION}/dist/assets` as const;
export const sliderIcon = `${assetsPath}/site-icons/2020-site-icons/before-and-after.svg` as const;

//temp storage
export const MODIFACE_LICENCE_KEYS = {
  SDM: '11E89820BCB9BEE1340227A3EF1B330E3698C7147D8D609412CDF1CC1424AB7CA9E5107EB401C93B300D2B6FFE73D62D0F6FC19C7148BBC15685F009354D2FA0',
  SDM_BLUE:
    '88C718FF72F6994141BB8D0A101666298AC7E0F61DFC7A9BA2445479A9100AA524F30C27D1FBAC75FDF58D683FF4DA221E80BA18C9C15853B0578DC066CC05FA',
  PHX: 'BC82CE29C83585259D59A22BF2D2C82D78CD824A28C4B7CFAEA8C66B17EE89B69560C079922168400E25E3376FB27C96A6036FD11162E39DEACDA20858C49AA8',
  PHX_BLUE:
    'C32EE1E3E9F145BCD9AA6BBB3022653F1C555CA2E5D7E307E630904851B7C3F7B73E4DAB6AC036195E7242E848A1FC53CBA58584FAABED2CABC139419E3A24C0',
  LOCAL:
    '37BFE6B60CB02463DFB3E67AC07BD2D9CD9C14FFBF2EEF69B4691E4E3D74A550D3032917EBAC039843CAF10C4D6962F68CC04D1D9A2103431C497ECC969FF112',
  PREPROD:
    '72F55F8837A9AE66C955CAA91E8409DC4679A63E9EE2382CBFCC3B904FCB4AACA10924B982605D10295D88B4EAA4E4130FC12372B36C94135B7795D3D18EE826',
} as const;
// env
export const HOST = {
  LOCAL: 'local.design-system.ca',
  //added for detecting env for modiface keys
  SDM: 'shop.shoppersdrugmart.ca',
  SDM_BLUE: 'gcp-blue-shoppersdrugmart.lblw.cloud',
  SDM_UAT: 'uat-shop.shoppersdrugmart.ca',
  PHX: 'magasiner.pharmaprix.ca',
  PHX_BLUE: 'gcp-blue-pharmaprix.lblw.cloud',
  PHX_UAT: 'uat-shop.pharmaprix.ca',
  PREPROD: 'bb-preprod.lblw.cloud',
  PHX_HOME: 'https://www.pharmaprix.ca',
  SDM_HOME: 'https://www.shoppersdrugmart.ca',
} as const;

export const DOMAIN_INCLUDES = {
  LOCAL: 'local',
} as const;

export enum Env {
  Prod,
  Uat,
  Dev,
  Local,
}

export const COOKIE_CONSENT = {
  SDM_ID: 'f0e2d678-c2ee-4dc7-8f4f-2144df9572af',
  PHX_ID: 'ab8cc384-1f8c-448b-aeee-2ea12b158848',
  IS_COOKIE_CONSENT: 'OptanonAlertBoxClosed',
};

export const GEOLOCATION_CODES = {
  COUNTRY: {
    CA: 'ca',
  },
  PROVINCE: {
    QC: 'qc',
  },
};

export const PAGE_ID = {
  HOME_ELECTRONICS: '/home-electronics',
  LANDING: '/home',
  BABY_CHILD: '/baby-child',
  PERSONAL_CARE: '/personal-care',
  WELLNESS_SHOP: '/page/the-wellness-shop',
  LUXURY_BEAUTY: '/page/luxurybeauty',
  PHARMACY_SERVICES: 'pharmacy-services',
  HOLIDAY_GIFTS: 'holiday-gifts',
  PROMOTIONS: 'promotions',
  SIGN_UP: 'sign-up',
  HEALTH: '/health',
  BEAUTY: '/beauty',
  BFCM: 'black-friday-cyber-monday',
  CONTACT_US: 'contact-us',
  STORE_LOCATOR: 'store-locator',
  STORE_DETAILS: 'store-details',
} as const;

export const SDM_PHARMA = 'https://mypharmacy.shoppersdrugmart.ca';
export const MONDO_PHARMA = 'https://mondossierpharma.ca';
export const beautyUrl = 'https://beauty.shoppersdrugmart.ca';
export const pcIdRegisterUrl = 'https://beauty.shoppersdrugmart.ca/register';
export const SHOPPERS_URL = 'https://www1.shoppersdrugmart.ca';
export const PHARMA_URL = 'https://www1.pharmaprix.ca/fr/home';
export const SHOPPERS_ECOMM_URL = 'https://shop.shoppersdrugmart.ca';
export const PHARMA_ECOMM_URL = 'https://magasiner.pharmaprix.ca';
export const PC_OPTIMUM_URL = 'https://www.pcoptimum.ca/';
export const GCP_BUCKET_URL = 'https://storage.googleapis.com/bb-react-frontend';

export const banners = {
  PHARMAPRIX: 'pharmaprix',
  SHOPPERS: 'shoppersdrugmart',
  PHX: 'phx',
} as const;

export const environment = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  PERFORMANCE: 'perf',
  UAT: 'uat',
  LOWER: 'lower',
} as const;

export const pharmacistNumber = '1-833-453-3336';

export const dermNumber = '1-855-437-6243';

export const LOCALE = {
  EN: 'en',
  FR: 'fr',
} as const;

export const INTL_LOCALE = {
  FR: 'fr-CA',
  EN: 'en-CA',
  FR_QC: 'fr-QC',
  EN_QC: 'en-QC',
} as const;

export const LoggedInStatus = {
  ANONYMOUS: 'ANONYMOUS',
  CURRENT: 'CURRENT',
  GUEST_LOGGED_IN: 'GUESTLOGGEDIN',
} as const;

export const MOUSE = {
  CLICK: 'click',
} as const;

export const APPIDS = {
  shoppersdrugmart: 'beautyboutique01',
  pharmaprix: 'beautyboutique02',
} as const;

export const KEY = {
  DOWN: 'keydown',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  TAB: 'Tab',
  ESCAPE: 'Escape',
  HOME: 'Home',
  END: 'End',
  SPACE: 'Spacebar',
} as const;

export const GESTURE = {
  TAP: 'tap',
  SWIPE_LEFT: 'swipeLeft',
  SWIPE_RIGHT: 'swipeRight',
  PINCH: 'pinch',
} as const;

export const POSITIONING = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
} as const;

export const comingSoonBanner = '';

export const contentful = {
  CONTENT_TYPES: {
    CAROUSEL: 'carousel',
    GENERIC_BANNER: 'genericBanner',
    FALLBACK_BANNER: 'fallbackBanner',
    FEATURE_TILES: 'featureTiles',
    BRAND_EDITORIAL_SPOTLIGHT: 'brandEditorialSpotlight',
    BEAUTY_BRAND_TILE: 'beautyBrandTile',
    BEAUTY_CATEGORY_TILE: 'beautyCategoryTile',
    FRONTSHOP_DEPARTMENT_TILE: 'departmentTiles',
    HEADING: 'heading',
    PAGE_BUILDER: 'pageBuilder',
    ANNOUNCEMENT_BAR: 'announcementBar',
    DIVIDER_LINE: 'divider',
    PRODUCT_SLIDER: 'productSlider',
    DISCLAIMER: 'disclaimer',
    FULL_PAGE_NOTIFICATION: 'fullPageNotification',
    CERTONA_SLIDER: 'certonaSlider',
    RICH_TEXT: 'richText',
    SPACING_TOKEN: 'spacing',
    HUB_PAGE_BUILDER: 'hubPageBuilder',
    SEARCH_REDIRECT: 'searchRedirect',
    AD_UNIT: 'adUnit',
  },
  COMPONENT_IDS: {
    LANDING_PAGE: 'landingPage',
    HOME_LANDING_PAGE: 'homeLandingPage',
    BEAUTY_LANDING_PAGE: 'beautyLandingPage',
    ELECTRONICS_LANDING_PAGE: 'electronicsLandingPage',
    BABY_AND_CHILD_LANDING_PAGE: 'babyandchildLandingPage',
    PERSONAL_CARE_LANDING_PAGE: 'personalcareLandingPage',
    HEALTH_LANDING_PAGE: 'healthLandingPage',
    PLP_PAGE: 'categoryPLP',
    SEARCH_PAGE: 'searchPLP',
    FAVOURITES_PLP: 'favouritesPLP',
    QUICK_SHOP_PLP: 'quickShopPlp',
    BRAND_SPOTLIGHT: (componentId: string) => `${componentId}BrandSpotlight`,
    EDITORIAL_SPOTLIGHT: (componentId: string) => `${componentId}EditorialSpotlight`,
  },
  NODE_TYPES: {
    HEADING_1: 'heading-1',
    HEADING_2: 'heading-2',
    HEADING_3: 'heading-3',
    HEADING_4: 'heading-4',
    HEADING_5: 'heading-5',
    HEADING_6: 'heading-6',
    HYPERLINK: 'hyperlink',
    PARAGRAPH: 'paragraph',
    LIST_ITEM: 'list-item',
    TEXT: 'text',
    UNORDERED_LIST: 'unordered-list',
    ORDERED_LIST: 'ordered-list',
  },
  RICH_TEXT_VARIANT: {
    FULL: 'full',
    HALF: 'half',
  },
} as const;

export const LANDING_PAGES = {
  HOME: 'homeLandingPage',
  BEAUTY: 'beautyLandingPage',
} as const;

export const REQUIRED_COOKIES = {
  CONTENTFUL_PREVIEW: 'contentful_preview',
  CONTENTFUL_PREVIEW_DATETIME: 'contentful_preview_datetime',
  RES_TRACKINGID: 'RES_TRACKINGID',
  CIAM_ACCESS_TOKEN: 'ciamAccessToken',
  BM_S: 'bm_s',
  BM_SS: 'bm_ss',
  BM_SC: 'bm_sc',
  BM_SO: 'bm_so',
  BM_LSO: 'bm_lso',
  AK_BMSC: 'ak_bmsc',
  BM_MI: 'bm_mi',
  BM_SV: 'bm_sv',
  BM_SZ: 'bm_sz',
  ABCK: '_abck',
  SEC_CPT: 'sec_cpt',
};

export const cookies = {
  CONTENTFUL_PREVIEW: 'contentful_preview',
  CONTENTFUL_PREVIEW_DATETIME: 'contentful_preview_datetime',
  RES_TRACKINGID: 'RES_TRACKINGID',
  CIAM_ACCESS_TOKEN: 'ciamAccessToken',
  CIAM_ACCESS_TOKEN_GLOBAL: 'ciamAccessTokenGlobal',
  ACCESS_TOKEN: 'accessToken',
  USER_STATUS: 'user_status',
  AKAMAI_ORIGIN_SESSION_COOKIE: 'Origin_Session_Cookie',
  ADOBE_TARGET_SNOW_PLOW_SYNC: 'target_sp_sync',
  FORCE_HELIOS: 'forceHelios',
  USER_PREFERRED_LOCALE: 'user_preferred_locale',
  SDM_LANG: 'Shoppers Drug Mart_Language',
  CONTENTFUL_SDM_LANG_CODE: 'ContentfulSDMLanguageCode',
  USER_SELECTED_PROVINCE: 'user-selected-province-id',
  SHOPPERSDRUGMART_CART_HELIOS: 'shoppersdrugmart-cart-helios',
  PHARMAPRIX_CART_HELIOS: 'pharmaprix-cart-helios',
  SSO_REFRESH_TOKEN: 'sso_refresh_token',
  SSO_LAST_VISITED_PAGE: 'sso_last_visited_page',
  SSO_ID_TOKEN: 'sso_id_token',
  FLIPP_STORE_CODE_208: 'flipp-store-code_208',
  FLIPP_STORE_CODE_2170: 'flipp-store-code_2170',
  POST_WAITING_ROOM: 'akavpau_vp',
  OLD_COOKIE_DOMAIN_SDM: '.shoppersdrugmart.ca',
  OLD_COOKIE_DOMAIN_PHX: '.pharmaprix.ca',
  BM_SZ: 'bm_sz',
  ABCK: '_abck',
  ACTIVE_SECURITY_HEADERS: 'active_security_headers',
} as const;

export const COOKIE_DELTA = {
  FOURTEEN_DAYS: 1209600000,
  ONE_DAY: 86400000,
  //temp for BFCM
  TWO_DAYS: 172800000,
} as const;

export const FEATURE_FLAGS_KEYS = {
  FRONTEND_PRODUCT_TILE: 'producttile',
  FRONTEND_CERTONA: 'certona',
  FRONTEND_FILTERS: 'filter',
} as const;

export const FEATURE_FLAGS = {
  FRONTEND_PRODUCT_TILE: 'frontend.featureFlag.productTile',
  FRONTEND_CERTONA: 'frontend.featureFlag.certona',
  FRONTEND_CERTONA_HOME_RECOMMENDATION: 'frontend.certona.home.recommendation',
  FRONTEND_CERTONA_LANDING_BEAUTY_RECOMMENDATION: 'frontend.certona.landing.beauty.recommendation',
  FRONTEND_FILTERS: 'filter',
  FRONTEND_DEPARTMENT_TILES: 'departmentTiles',
} as const;

// PRODUCT
// server sends uppercase! ex SHADE
export const VARIANT_TYPE = {
  SHADE: 'SHADE',
  SIZE: 'SIZE',
  DEFAULT: 'DEFAULT',
  COLOR: 'COLOR',
} as const;

export const SELECT_TYPE = {
  QUANTITY: 'quantity',
} as const;

export const VARIANT_TYPE_LABELS = {
  colour: 'colour',
  size: 'size',
  shade: 'shade',
} as const;

/* The module name is the ID of the element within which the react app will be mounted. This name is set up on the backend. */
export const MODULES = {
  PLP: 'show-plp',
  SEARCH_PLP: 'search-plp',
  NAV: 'beauty-frontend-nav',
  LANDING_PAGE: 'landing-page',
  LANDING_PAGE_BEAUTY: 'landing-page-beauty',
  FOOTER: 'beauty-frontend-footer',
  FAVOURITES_PLP: 'favourites-plp',
  PAGE: 'Page',
  YOU_CAM: 'you-cam',
  PDP: 'modernized-pdp',
  CHECKOUT: 'modernized-checkout',
  ORDER_CONFIRMATION: 'order-confirmation',
  CONTENTFUL_PAGE: 'contentful',
  BEAUTY_SHOPPERS: 'beauty-shoppers',
  MY_ACCOUNT: 'my-account',
  MY_ACCOUNT_SHIPPING: 'my-acc-shipping',
  MY_BAG: 'my-bag',
  QUICK_SHOP_PLP: 'quick-shop-plp',
} as const;

export type PlpType = typeof MODULES.PLP | typeof MODULES.SEARCH_PLP | typeof MODULES.QUICK_SHOP_PLP;

export const RMP_OPTS = {
  ENABLE_NEXT_GEN: {
    name: 'bff.exp.next_gen_active',
    value: 'variant',
  },
  IS_SRP_REDIRECT: {
    name: 'bff.exp.is_srp_redirect',
    value: 'variant',
  },
};

export const PLP_QUERY_PARAM_KEYS = {
  SORT_PARAM_KEY: 'sort',
  PAGE_PARAM_KEY: 'page',
  QUERY_PARAM_KEY: 'q',
  SEARCH_TEXT_PARAM_KEY: 'text',
  SEARCH_REDIRECT_PARAM_KEY: 'originalTarget',
  SLUG: 'slug',
  LANG_KEY: 'lang',
  NAV: 'nav',
} as const;

export const CONTEXT = {
  PLP_CONTEXT: 'PlpContext',
  LANDING_CONTENT: 'LandingContext',
  PAGE_CONTENT: 'Page',
  CHECKOUT_CONTEXT: 'CheckoutContext',
  FEATURE_FLAG_CONTEXT: 'FeatureFlagContext',
} as const;

export const FAVOURITES_PAGE_ACTIONS = {
  ADD_TO_BAG: 'ADD_TO_BAG',
  ADD_TO_BAG_ERROR: 'ADD_TO_BAG_ERROR',
  CONFIRMATION: 'CONFIRMATION',
  HIDE_SCHEDULE_THREE: 'HIDE_SCHEDULE_THREE',
} as const;

export const COLORS = {
  CHARCOAL: '#2f2f2f',
  WHITE: '#fff',
} as const;

export const VOUCHER_STATUS = {
  VALID: 'valid',
  INVALID: 'invalid',
} as const;

export const SCREEN_SIZE_SUFFIX = {
  DESKTOP: '_b1_',
  TABLET: '_b2_',
  MOBILE: '_b3_',
} as const;

export const DEVICES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
} as const;

export const MOBILE_ICON_SIZE = '40';
export const DESKTOP_ICON_SIZE = '50';

export const NAV_ELEMENTS = {
  MOBILE_MENU: 'fs-mobile-menu',
} as const;

export const MICROFORM_FIELDS = {
  CREDIT_CARD: 'card-number-container',
  CVV: 'securityCode-container',
} as const;

export const ACCESSIBILITY_KEYCODES = {
  TAB: 9,
  SPACE: 32,
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
} as const;

export const CERTONA_PAGES = {
  HOME: 'HOME',
  FAVOURITES: 'FAVOURITES',
  SEARCH: 'SEARCH',
  CATEGORY: 'CATEGORY',
  PDP_FRONT_SHOP: 'PDP_FRONT_SHOP',
  PDP_PRESTIGE: 'PDP_PRESTIGE',
  A2C_OVERLAY: 'A2C_OVERLAY',
  DEFAULT: 'default',
  QUICK_SHOP_PLP: 'QUICK_SHOP_PLP',
  MAY_LIKE_QUICK_SHOP_PLP: 'MAY_LIKE_QUICK_SHOP_PLP',
} as const;

export const CERTONA_SCHEMES = {
  SEARCH: 'search3_rr',
  CATEGORY: 'category3_rr',
  PDP_PRESTIGE: 'product5_rr',
  PDP_FRONT_SHOP: 'product7_rr',
  A2C_OVERLAY: 'cartoverlay3_rr',
  QUICK_SHOP_PLP: 'home6_rr',
  MAY_LIKE_QUICK_SHOP_PLP: 'nofavourites3_rr',
} as const;

// used for PDP and add to cart (query parameter)
export const CERTONA_EVENTS = {
  PDP: 'product',
  A2C_OVERLAY: 'addtocart_op',
} as const;

export const DATASET_ATTRIBUTES = {
  LANDING_PAGE: 'landingpagename',
  CERTONA_PAGE: 'certona-url',
  USER_STATUS: 'userstatus',
  DEPARTMENT_TILES: 'departmenttiles',
  PDP: 'pdp',
  INSTORE_INVENTORY: 'instoreinventory',
  QUICK_SHOP: 'isquickshop',
  CART_MERGED_FLAG: 'mergecartflag',
} as const;

export const DATASET_ATTRIBUTES_VALUES = {
  SOFT_LOGIN: 'softLoggedIn',
  HARD_LOGIN: 'hardLoggedIn',
} as const;

export const LANGUAGES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
} as const;

export const RATINGS_MAP = {
  oneStarUp: {
    img: oneStarUp,
    location: 4,
    value: 1,
  },
  twoStarUp: {
    img: twoStarUp,
    location: 3,
    value: 2,
  },
  threeStarUp: {
    img: threeStarUp,
    location: 2,
    value: 3,
  },
  fourStarUp: {
    img: fourStarUp,
    location: 1,
    value: 4,
  },
  fiveStar: {
    img: fiveStar,
    location: 0,
    value: 5,
  },
} as const;

export const BADGES_UID = {
  NEW: 'new',
  THOUGHTFUL_CHOICES: 'thoughtfulChoices',
  ONLINE_ONLY: 'onlineonly',
  EXCLUSIVE: 'exclusive',
  VIRTUAL_TRYON: 'VirtualTryOn',
} as const;

//filters
export const DEFAULT_FILTER_ITEMS = 8;
export const FILTER_RATINGS_CODE = 'frontshopStarRating';
export const FILTER_SHOP_BY_CODE = 'shopByCollection';
export const FILTER_BRANDNAME_CODE = 'brandName';
export const NUM_PRODUCTS_PER_PAGE = 36; // Number should be a multiple of 12 to show full rows of 4, 3, or 2 on all breakpoints

export const OPERATION_NAMES = {
  GET_FOOTER: 'GetFooter',
  GET_NAVIGATION: 'GetNavigation',
  GET_NAVIGATION_MOBILE: 'GetNavigationMobile',
  ADD_TO_CART: 'addToCart',
  GET_RECOMMENDED_PRODUCTS: 'GetRecommendedProducts',
} as const;

// youcam
export const YOU_CAM = {
  youCamId: 'YMK-module',
  // SDK default size is 480 x 360, customized to accommodate overlay
  mobileWidth: 320,
  desktopWidth: 360,
  iframeHeight: 520,
  sdkSrc:
    process.env.NODE_ENV != environment.PRODUCTION
      ? 'https://plugins-media.makeupar.com/c37391-dev/sdk.js'
      : 'https://plugins-media.makeupar.com/c37391/sdk.js',
  apiKey: 'kITCkHCoME9x0PmEZlHMcA',
  scoreLegend: [
    { key: '0 - 68:', description: 'youCam.resultLegend.fair' },
    { key: '69 - 75:', description: 'youCam.resultLegend.good' },
    { key: '75 - 100:', description: 'youCam.resultLegend.great' },
  ],
  SCORES_MAP: {
    ['moisture']: ['HYDRATING', 'DRYNESS', 'HYDRATION'],
    ['redness']: ['SENSITIVITY_AND_REDNESS', 'REDNESS', 'IRRITATION'],
    ['texture']: ['PORES', 'DULLNESS_AND_UNEVEN_SKINTONE', 'EXFOLIATION'],
    ['wrinkles']: ['ANTI_AGING', 'FINE_LINES', 'LIFTING', 'FIRMING', 'WRINKLES'],
    ['oiliness']: ['SHINE'],
    ['darkCircles']: ['DARK_CIRCLES'],
    ['acne']: ['ACNE'],
    ['ageSpots']: ['BRIGHTENING', 'UNEVEN_SKINTONE'],
  },
} as const;

export const DELIVERY_MODES = {
  STANDARD: 'standard',
  PICKUP: 'pickup',
} as const;

export const IMAGE_MAIN_KEY_MAP = {
  DESKTOP: 'size800',
  TABLET: 'size400',
  MOBILE: 'size400', // note: the medium image looks better than the small on mobile 400px vs 200px as its scaled down instead of up on larger mobile displays
} as const;

export const IMAGE_ZOOMED_KEY_MAP = {
  DESKTOP: 'size800',
  TABLET: 'size400',
  MOBILE: 'size400',
} as const;

export const NOTIFICATION_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  MESSAGE: 'message',
} as const;

export const NOTIFICATION_LEVELS = {
  PAGE_LEVEL: 'pageLevel',
  SECTION_LEVEL: 'sectionLevel',
} as const;

export const CUSTOMER_TYPES = {
  GUEST: 'guest',
  REGISTERED_PCID: 'registeredPcid',
  PC_OPTIMUM_MEMBER: 'pcOptimumMember',
  PC_OPTIMUM_INSIDERS_MEMBER: 'pcOptimumInsidersMember',
} as const;

export const SENTRY = {
  DSN: 'https://3f178426902b4944b9e57fed1ece1506@sentry-prod.loblaw.digital/3',
  SENTRY_DEFAULT_SAMPLE_RATE: 0.0,
  CATEGORY: 'Category',
  LOCAL_ENV: 'local',
  HOME: 'Landing',
} as const;

// in Hybris - see commons.yml - # PowerReviews secrets
export const POWER_REVIEWS = {
  EN: {
    MERCHANT_GROUP_ID: '80915',
    MERCHANT_ID: '867678',
    API_KEY: 'f40b12ae-7efa-423a-8f12-cb902debf1c0',
  },
  FR: {
    MERCHANT_GROUP_ID: '80977',
    MERCHANT_ID: '373705',
    API_KEY: 'ab91132b-6135-4f27-8fc9-957b4d817e25',
  },
} as const;

export const FORM_STATES = {
  STANDARD: 'standard',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

export const MONTH_ABBREVIATONS = {
  EN: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  FR: ['jan', 'févr', 'mars', 'avril', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
} as const;

export const DAYS_OF_WEEK_MAP: { [id: number]: string } = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export const PROMOTION_TYPES = {
  OPTIMUM: 'optimum',
  GIFT: 'gift',
  PWP: 'pwp',
  SALE: 'onsale',
  CLEARANCE: 'clearance',
  PM3: 'pm3',
  FREE_SHIPPING: 'freeshipping',
} as const;

export const FLEX_DIRECTION = {
  COLUMN: 'column',
} as const;

export const MODIFACE_MODES = {
  LIVE_MODE: 'live-mode',
  PHOTO_MODE: 'photo-mode',
} as const;

export const HYBRIS_ERRORS = {
  AUTHORIZATION_FAILURE_ACCOUNT_MARKED_FOR_DELETION: 'AUTHORIZATION_FAILURE_ACCOUNT_MARKED_FOR_DELETION',
} as const;

export const MODIFACE_USER_JOURNEY_STEP = {
  CONSENT: 'consent',
  START_LIVE: 'start-live',
  LIVE: 'live',
  START_PHOTO: 'start-photo',
  PHOTO: 'photo',
  EXIT: 'exit',
  PHOTO_TAKEN_LIVE: 'photo-taken-live',
  NON_VTO_VARIANT_LIVE: 'non-vto-variant-live',
  NON_VTO_VARIANT_PHOTO: 'non-vto-variant-photo',
  SWATCH_CHANGE_LIVE: 'swatch-change-live',
  NOT_IN_VTO: 'not-in-vto',
} as const;

export const MODIFACE_USER_JOURNEY_ERROR_STEP = {
  PHOTO_NOT_UPLOADED_ERROR: 'photo-not-uploaded',
  WEBCAM_ERROR: 'web-cam-error',
  NO_FACE_ERROR: 'face-not-detected',
  VTO_INITIATION_ERROR: 'vto-initiation-error',
} as const;

export const MODIFACE_ELEMENT_ID = {
  LIVE_CAMERA_LOADER: 'live-camera-loader',
  UPLOAD_PHOTO_LOADER: 'upload-photo-loader',
  LIVE_CAMERA_PHOTO: 'live-camera-photo',
  DOWNLOAD_LIVE_IMAGE_LINK: 'download-live-image-link',
  DOWNLOAD_PHOTO_IMAGE_LINK: 'download-photo-image-link',
} as const;

export const MODIFACE_ERRORS = {
  FACE_NOT_DETECTED: 'Error: ERROR_NO_FACE: no face is detected by facetracker.',
  ERROR_FILESIZE: 'Error: ERROR_FILESIZE: image file size is too large.',
  ERROR_SMALL_IMG: 'Error: ERROR_SMALL_IMG: image is too small.',
  ERROR_FILETYPE: 'Error: ERROR_FILETYPE: image file type is incompatiable.',
  ERROR_WEBCAM: 'Error: ERROR_WEBCAM: webcam is unavailable.',
} as const;
export const VTO_INITIATION_ERRORS = {
  ERROR_MODULE_CREATED: 'Makeup module creation has failed.',
  ERROR_MODULE_DEACTIVATED: 'Makeup module does not exist in both photo and live mode.',
  ERROR_PRODUCT_CATEGORY: 'ERROR_PRODUCT_CATEGORY',
  ERROR_NO_LOOKID: 'ERROR_NO_LOOKID',
} as const;

export const STORE_TYPES = {
  SHOPPERS_DRUG_MART: 1,
  PHARMAPRIX: 2,
} as const;

export const GEO_CODES = {
  NO_ERROR: 0,
  DISABLED: 1,
  CODE_NOT_AVAILABE: 2,
  GEO_LOCATION_OFF_ERROR_MESSAGE:
    'Please update your browser setting to share location or search stores using your address or postal code.',
  BACKEND_ERROR_MESSAGE: 'Sorry, stock details are unavailable at the moment. Please check back later or shop online.',
  ERROR_TYPE: 'FindInStore',
  API_ERROR: 3,
} as const;

export const GOOGLE_AUTOCOMPLETE_CODES = {
  OK: 'OK',
  API_ERRORS: ['NOT_FOUND', 'REQUEST_DENIED', 'UNKNOWN_ERROR', 'OVER_QUERY_LIMIT'],
  ZERO_RESULTS: 'ZERO_RESULTS',
  INVALID_REQUEST: 'INVALID_REQUEST',
} as const;

export const MEDALLIA_FEEDBACK_BUTTON_ID = 'nebula_div_btn';

export const DATA_USER_STATUS = {
  HARD_LOGGED_IN: 'hardLoggedIn',
  SOFT_LOGGED_IN: 'softLoggedIn',
  GUEST_LOGGED_IN: 'guestLoggedIn',
  ANONYMOUS: 'anonymous',
} as const;

export const VIEW_RESOLVER = {
  TENANT_ID_SDM: 'SHOPPERS_DRUG_MART',
  APP_TYPE_WEB: 'Web',
  API_VERSION_V1: 'v1',
  API_VERSION_V2: 'v2',
  MAIN_CONTENT_COLLECTION: 'mainContentCollection',
  PAGE_ID_HEADER_FOOTER: 'm4',
  PAGE_LAYOUT_SINGLE_COLUMN: 'pageLayoutSingleColumn',
  HEADER: 'header',
  SLUG: 'slug',
  BRAND: 'brandName',
  SEARCH_TERM: 'searchTerm',
  SEARCH_REDIRECT: 'searchRedirectData',
  CATEGORY: 'categoryId',
  HOME_PAGE: 'home',
} as const;

export const PLP_PAGE_TYPE = {
  CATEGORY: 'category',
  SEARCH: 'search',
  BRAND: 'brand',
  COLLECTION: 'collection',
  PREVIOUS_PURCHASE: 'previousPurchase',
  FAVOURTIE: 'favourite',
};

export const BFF_PATH = {
  CATEGORY: 'sdui/category/',
  SEARCH: 'sdui/search?q=',
  BRAND: 'sdui/brands/',
  COLLECTION: 'sdui/category/',
  PREVIOUS_PURCHASE: 'sdui/category/previous-purchase',
  FAVOURTIE: 'sdui/category/favourite',
  DYNAMIC: 'sdui/dynamic-view',
};

export const ZERO_HOURS = '00:00';
export const MIDNIGHT_HOURS = '24:00';

export const TO = {
  EN: 'to',
  FR: 'à',
} as const;

export const ACCOUNT_PAGES = {
  ACCOUNT_SETTINGS: { NAME: 'Account Settings', URL: '/my-account' },
  PC_OPTIMUM: { NAME: 'PC Optimum', URL: '/optimum' },
  ORDER_HISTORY: { NAME: 'Order History', URL: '/orders' },
  SHIPPING_INFORMATION: { NAME: 'Shipping Information', URL: '/address-book' },
  PAYMENT_INFORMATION: { NAME: 'Payment Information', URL: '/payment-details' },
  PREVIOUS_PURCHASES: {
    NAME: 'Previous Purchases',
    URL: '/previous-purchases',
  },
} as const;

export const SORT_OPTIONS = {
  TRENDING: 'trending',
  PRICE_ASC: 'price-asc',
  PRICE_DESC: 'price-desc',
  TOP_RATED: 'top-rated',
  NEWEST: 'newest',
} as const;

export const NAV_CONFIGS = {
  basePath: ACCOUNT_PAGES.ACCOUNT_SETTINGS.URL,
  links: [
    {
      pathname: '',
      pagename: ACCOUNT_PAGES.ACCOUNT_SETTINGS.NAME,
    },
    {
      pathname: ACCOUNT_PAGES.PC_OPTIMUM.URL,
      pagename: ACCOUNT_PAGES.PC_OPTIMUM.NAME,
    },
    {
      pathname: ACCOUNT_PAGES.ORDER_HISTORY.URL,
      pagename: ACCOUNT_PAGES.ORDER_HISTORY.NAME,
    },
    {
      pathname: ACCOUNT_PAGES.SHIPPING_INFORMATION.URL,
      pagename: ACCOUNT_PAGES.SHIPPING_INFORMATION.NAME,
    },
    {
      pathname: ACCOUNT_PAGES.PAYMENT_INFORMATION.URL,
      pagename: ACCOUNT_PAGES.PAYMENT_INFORMATION.NAME,
    },
    {
      pathname: '/favourite',
      pagename: 'Favourites',
    },
  ],
} as const;

export const AD_VIEW_TRIGGER = {
  viewDurationMs: 1000, // millisecond ※ default value is 1000
  threshold: 0.5, // between 0 and 1 ※ default value is 0.5
} as const;

export const UNIT_OF_MEASURMENT = {
  MILLIMETERS: 'mL',
  GRAMS: 'g',
} as const;

export const PLP_CATEGORY_ID = {
  NEW_ARRIVALS: 'FS-new-arrivals',
} as const;

export const NEW_ARRIVALS_FILTER_PREFIX = ':trending:category:';
// Ad Unit constant starts
export const GAM_TEST = 'gam_test';
export const PLP_ABOVE_GRID = 'plpAboveGrid';
export const PLP_FORECASTING_ABOVE_GRID = 'plpForcastingAboveGrid';

/**
 * An object containing type to size mapping used for AdUnits
 */
export const AD_TYPE_SIZE_MAP = {
  leaderboard: [728, 90],
  largeMBanner: [320, 100],
  mBanner: [320, 50],
  bigBox: [300, 600],
  box: [300, 250],
  tracking: [1, 1],
} as const;

/**
 * An object containing viewport sizes to type mapping for AdUnits
 */
export const AD_VIEWPORT_SIZES_MAP = {
  [PLP_ABOVE_GRID]: {
    [DEVICES.MOBILE]: [AD_TYPE_SIZE_MAP.mBanner, AD_TYPE_SIZE_MAP.largeMBanner],
    [DEVICES.TABLET]: [AD_TYPE_SIZE_MAP.leaderboard, AD_TYPE_SIZE_MAP.largeMBanner, AD_TYPE_SIZE_MAP.mBanner],
    [DEVICES.DESKTOP]: [AD_TYPE_SIZE_MAP.leaderboard],
  },
} as const;

export const QUICK_SHOP = {
  DESKTOP_ICON_DIMENSION: '198',
  MOBILE_ICON_DIMENSION: '140',
} as const;

// click macro tracking values from GAM meta data
export const DATA_CLICK_MACRO = 'data-click-macro';
// data partial to generate dynamic click through url from GAM meta data
export const DATA_PARTIAL_HREF = 'data-partial-href';
// click pixel tracking values from GAM meta data
export const DATA_CLICK_PIXEL = 'data-click-pixel';
// house campaign id , anything else would be paid vendor campaign, aka 'sponsored' ads
export const GAM_HOUSE_AD_ID = 4906587565;
// Global GPT internal key to fetch ad unit
export const GPT_KEY = '21915145997';
// Google recommended rejecting characters in GAM KV targetting
export const GAM_REJECTS = [`"`, `’`, `=`, `!`, `\\+`, `#`, `\\*`, `~`, `;`, `\\^`, `\\(`, `\\)`, `\\<`, `\\>`, `,`];
// brand name constant to filter out the brand name in the url query param
export const BRAND_NAME = 'brandName';
// dynamic categories GAM slot path for categories pages
export const DYNAMIC_CATEGORY_SLOT = '{{category_id}}';
// Restrictions to hide/show display ad units on luxury brand pages
export const CATEGORIES_RESTRICTIONS = 'propertiesCategoriesRestriction';
// Slot path in GAM for Beauty slot
export const AD_SLOT_BEAUTY = {
  en: 'Beauty',
  fr: 'Beaute',
} as const;
// Slot path in GAM for Brand slot
export const AD_SLOT_BRAND = 'Brand';
// Search result page type
export const SEARCH_RESULT = 'search-results';
// Brand listing plp page type
export const BRAND_PLP = 'brand-plp';
// GAM placement type, bp = brand page line item
export const AD_BRAND_PLACEMENT = 'bp';
// GAM placement type, plp = product list page line item
export const AD_CLP_PLACEMENT = 'plp';
// GAM placement type, srl = search result line item
export const AD_SRP_PLACEMENT = 'srl';
// Customer cart to store in localStorage
export const CUSTOMER_CART_ID = 'customer-cart';

export const AD_PLP_MAP = {
  [MODULES.SEARCH_PLP]: contentful.COMPONENT_IDS.SEARCH_PAGE,
  [MODULES.PLP]: contentful.COMPONENT_IDS.PLP_PAGE,
} as const;

// Ad Unit constant ends

export const enum PathNames {
  HOME_PAGE = '/',
  BEAUTY_LANDING_PAGE = '/beauty',
  PERSONAL_CARE_LANDING_PAGE = '/personal-care',
  HEALTH_LANDING_PAGE = '/health',
  BABY_AND_CHILD_LANDING_PAGE = '/baby-child',
  ELECTRONICS_LANDING_PAGE = '/home-electronics',
}

export enum PhxProvince {
  QC = 'QC',
}

export enum SdmProvince {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  PE = 'PE',
  SK = 'SK',
  YT = 'YT',
  ON = 'ON',
}

export const HeadlineVariants = {
  LARGE_01: {
    headlineSize: 1,
  },
  LARGE_02: {
    headlineSize: 2,
  },
  MEDIUM_03: {
    headlineSize: 3,
  },
  SMALL_04: {
    headlineSize: 4,
  },
};

export const KAMELEOON = {
  CUSTOM_DATA: {
    SP_APP_ID: 'sp_app_id',
    SP_DOMAIN_USERID: 'sp_domain_userid',
    SP_DOMAIN_SESSIONID: 'sp_domain_sessionid',
  },
} as const;

export const enum SSO {
  CODE = 'code',
  STATE = 'state',
  RESPONSE = 'response',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SUCCESS = 'SUCCESS',
}

export const MERCHANT_ID = { SDM: '208', PHX: '2170' } as const;

export const ADA_CHATBOT = {
  GET_HELP_BUTTON: 'ada-button-frame',
};

export const BB_SERVICES: { [id: string]: { title: string; description: string; image: string } } = {
  'beautyRx Skin Care': {
    title: 'skinCareTitle',
    description: 'skinCareBody',
    image:
      'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/ad-hoc/mb-15512-store-details-page/beautyRX%20Skincare.png',
  },
  'Makeup Consultation': {
    title: 'makeupConsultTitle',
    description: 'makeupConsultBody',
    image:
      'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/ad-hoc/mb-15512-store-details-page/Mkaeup%20consultation.png',
  },
} as const;

export const SITE_ID = {
  PHX: 'phx',
  SDM: 'sdm',
} as const;

export const FRONTEND = 'frontend';

export const OS_TYPE = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown',
} as const;
