import { useFireworkStore } from '@/store/firework/fireworkStore';
import { initializeFireworkHydration } from '@/utils/firework/firework-hydration';
import { useEffect } from 'react';

export const FireworkInit = () => {
  const store = useFireworkStore();

  useEffect(() => {
    const cleanup = initializeFireworkHydration(store);
    return () => {
      cleanup();
    };
  }, [store]);

  return null;
};
