import { type AppProps } from 'next/app';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import UniversalCookies from 'universal-cookie';

import Layout from '@/components/layout';
import { IViewDefinition } from '@/components/authored-page-routes/authored-page-routes.interface';
import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';

import { getSnowplowUserData, initializeAnalytics } from '@/utils/analytics/analytics-utils';
import { useKameleoonEngine } from '@/utils/kameleoon/use-kameleoon-engine';
import { initializeSalesfloorAccessibility, isAndroidDevice, isPharmaprixBanner } from '@/utils';
import { useUpdateAppBannerStyles } from '@/hooks';
import { fireworkTrackPageView, useFireworkAnalytics } from '@/utils/firework/firework-analytics';
import { COOKIE_CONSENT, GCP_BUCKET_URL, PAGE_ID } from '@/data/constants';

import '../styles/globals.scss';
import { FireworkProvider } from '@/store/firework/fireworkStore';
import { FireworkInit } from '@/components/firework/firework-init';

import {otelRegister, refreshOtelConfigs, useOtelNavigate} from '@/telemetry-sdk/csr';

import { pageKeyMap } from 'Data/telemetry';

export interface PreRenderProps {
  viewDefinitionResults: {
    viewDefinition: IViewDefinition;
    currentUrlBase: string;
  } | null;
  themeTokens: object | null;
  cartData: object | null;
  kamFlags: Record<string, IKamFlag> | null;
}

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    const { worker } = await import('../mocks/browser');
    return worker.start({ onUnhandledRequest: 'bypass' });
  }
}

const cookieStore = new UniversalCookies();

otelRegister({ bffUrl: process.env.NEXT_PUBLIC_BFF_URL, pageKeyMap, userId: getSnowplowUserData().domainUserId });

function MyApp({ Component, pageProps }: AppProps<PreRenderProps>) {
  useOtelNavigate();

  const router = useRouter();
  enableMocking();
  const { cartData, viewDefinitionResults, kamFlags } = pageProps;
  const { SDM_ID, PHX_ID, IS_COOKIE_CONSENT } = COOKIE_CONSENT;
  const domainScriptId = isPharmaprixBanner() ? PHX_ID : SDM_ID;
  const isCookieConsented = !!cookieStore.get(IS_COOKIE_CONSENT);

  // Apply banner style updates when the app banner is present
  useUpdateAppBannerStyles();

  useFireworkAnalytics();

  useEffect(() => {
    initializeAnalytics();
    initializeSalesfloorAccessibility();
  }, []);

  useKameleoonEngine(); //experimentation tool - JS script supports visual editor experiments

  useEffect(() => {
    refreshOtelConfigs(kamFlags).catch(err => console.error('Failed to refresh otel telemetry configurations:', err));
  }, [kamFlags]);

  // Track page views
  useEffect(() => {
    // Get current page path
    const currentPath = router.pathname;

    // Track page view with correct page type
    switch (currentPath) {
      case '/shoppingbag':
        fireworkTrackPageView('shoppingbag');
        break;
      case '/purchase':
        fireworkTrackPageView('purchase');
        break;
    }
    // Track page view when route changes
  }, [router.pathname]); // Re-run when path changes

  return (
    <FireworkProvider>
      <FireworkInit />
      <Layout cartData={cartData} viewDefinitionResults={viewDefinitionResults} kamFlags={kamFlags}>
        <Component {...pageProps} />
        {/* embed Firework carousel/grid widgets */}
        <Script
          className="optanon-category-C0003"
          type="text/plain" // text/plain default type for Quebec CCM
          strategy="afterInteractive"
          src="//asset.fwcdn3.com/js/embed-feed.js"
        />
        <Script id="gtm-script" strategy="lazyOnload">
          {`!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-TCJKP3F",m.parentNode.insertBefore(r,m)}(window,document,"script","dataLayer");`}
        </Script>
        {isAndroidDevice() && (
          <Script id="app-banner" strategy="lazyOnload" src={`${GCP_BUCKET_URL}/static/app-banner.js`} />
        )}
        <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" strategy="lazyOnload" />
        {/*embed ada chatbot */}
        {router?.asPath?.includes(PAGE_ID.CONTACT_US) && (
          <Script id="__ada" strategy="lazyOnload" data-handle="sdm-lcl" src="https://static.ada.support/embed2.js" />
        )}
        <Script id="ad-blocker" strategy="lazyOnload">
          {`
          const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
          try {
            fetch(new Request(googleAdUrl)).catch((_) => dataLayer.push({ event: 'AdBlocker' }));
          } catch (e) {
            dataLayer.push({ event: 'AdBlocker' });
          }
        `}
        </Script>
        {!isCookieConsented ? (
          <>
            <Script
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              strategy="lazyOnload"
              data-document-language="true"
              charSet="UTF-8"
              data-domain-script={domainScriptId}
            />
            <Script id="opt-wrapper" strategy="lazyOnload" src={`${GCP_BUCKET_URL}/static/opt-wrapper.js`} />
          </>
        ) : null}
      </Layout>
    </FireworkProvider>
  );
}

export default MyApp;
